@charset "UTF-8";
/* fira-sans-200italic - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 200;
  src: url("../fonts/fira-sans-v10-cyrillic_latin-200italic.eot");
  /* IE9 Compat Modes */
  src: local("Fira Sans ExtraLight Italic"), local("FiraSans-ExtraLightItalic"), url("../fonts/fira-sans-v10-cyrillic_latin-200italic.eot?#iefix") format("embedded-opentype"), url("../fonts/fira-sans-v10-cyrillic_latin-200italic.woff2") format("woff2"), url("../fonts/fira-sans-v10-cyrillic_latin-200italic.woff") format("woff"), url("../fonts/fira-sans-v10-cyrillic_latin-200italic.ttf") format("truetype"), url("../fonts/fira-sans-v10-cyrillic_latin-200italic.svg#FiraSans") format("svg");
  /* Legacy iOS */ }

/* fira-sans-200 - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/fira-sans-v10-cyrillic_latin-200.eot");
  /* IE9 Compat Modes */
  src: local("Fira Sans ExtraLight"), local("FiraSans-ExtraLight"), url("../fonts/fira-sans-v10-cyrillic_latin-200.eot?#iefix") format("embedded-opentype"), url("../fonts/fira-sans-v10-cyrillic_latin-200.woff2") format("woff2"), url("../fonts/fira-sans-v10-cyrillic_latin-200.woff") format("woff"), url("../fonts/fira-sans-v10-cyrillic_latin-200.ttf") format("truetype"), url("../fonts/fira-sans-v10-cyrillic_latin-200.svg#FiraSans") format("svg");
  /* Legacy iOS */ }

/* fira-sans-300 - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/fira-sans-v10-cyrillic_latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Fira Sans Light"), local("FiraSans-Light"), url("../fonts/fira-sans-v10-cyrillic_latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/fira-sans-v10-cyrillic_latin-300.woff2") format("woff2"), url("../fonts/fira-sans-v10-cyrillic_latin-300.woff") format("woff"), url("../fonts/fira-sans-v10-cyrillic_latin-300.ttf") format("truetype"), url("../fonts/fira-sans-v10-cyrillic_latin-300.svg#FiraSans") format("svg");
  /* Legacy iOS */ }

/* fira-sans-300italic - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/fira-sans-v10-cyrillic_latin-300italic.eot");
  /* IE9 Compat Modes */
  src: local("Fira Sans Light Italic"), local("FiraSans-LightItalic"), url("../fonts/fira-sans-v10-cyrillic_latin-300italic.eot?#iefix") format("embedded-opentype"), url("../fonts/fira-sans-v10-cyrillic_latin-300italic.woff2") format("woff2"), url("../fonts/fira-sans-v10-cyrillic_latin-300italic.woff") format("woff"), url("../fonts/fira-sans-v10-cyrillic_latin-300italic.ttf") format("truetype"), url("../fonts/fira-sans-v10-cyrillic_latin-300italic.svg#FiraSans") format("svg");
  /* Legacy iOS */ }

/* fira-sans-regular - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/fira-sans-v10-cyrillic_latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Fira Sans Regular"), local("FiraSans-Regular"), url("../fonts/fira-sans-v10-cyrillic_latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/fira-sans-v10-cyrillic_latin-regular.woff2") format("woff2"), url("../fonts/fira-sans-v10-cyrillic_latin-regular.woff") format("woff"), url("../fonts/fira-sans-v10-cyrillic_latin-regular.ttf") format("truetype"), url("../fonts/fira-sans-v10-cyrillic_latin-regular.svg#FiraSans") format("svg");
  /* Legacy iOS */ }

/* fira-sans-italic - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/fira-sans-v10-cyrillic_latin-italic.eot");
  /* IE9 Compat Modes */
  src: local("Fira Sans Italic"), local("FiraSans-Italic"), url("../fonts/fira-sans-v10-cyrillic_latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/fira-sans-v10-cyrillic_latin-italic.woff2") format("woff2"), url("../fonts/fira-sans-v10-cyrillic_latin-italic.woff") format("woff"), url("../fonts/fira-sans-v10-cyrillic_latin-italic.ttf") format("truetype"), url("../fonts/fira-sans-v10-cyrillic_latin-italic.svg#FiraSans") format("svg");
  /* Legacy iOS */ }

/* fira-sans-500 - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/fira-sans-v10-cyrillic_latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Fira Sans Medium"), local("FiraSans-Medium"), url("../fonts/fira-sans-v10-cyrillic_latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/fira-sans-v10-cyrillic_latin-500.woff2") format("woff2"), url("../fonts/fira-sans-v10-cyrillic_latin-500.woff") format("woff"), url("../fonts/fira-sans-v10-cyrillic_latin-500.ttf") format("truetype"), url("../fonts/fira-sans-v10-cyrillic_latin-500.svg#FiraSans") format("svg");
  /* Legacy iOS */ }

/* fira-sans-500italic - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/fira-sans-v10-cyrillic_latin-500italic.eot");
  /* IE9 Compat Modes */
  src: local("Fira Sans Medium Italic"), local("FiraSans-MediumItalic"), url("../fonts/fira-sans-v10-cyrillic_latin-500italic.eot?#iefix") format("embedded-opentype"), url("../fonts/fira-sans-v10-cyrillic_latin-500italic.woff2") format("woff2"), url("../fonts/fira-sans-v10-cyrillic_latin-500italic.woff") format("woff"), url("../fonts/fira-sans-v10-cyrillic_latin-500italic.ttf") format("truetype"), url("../fonts/fira-sans-v10-cyrillic_latin-500italic.svg#FiraSans") format("svg");
  /* Legacy iOS */ }

/* fira-sans-700 - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/fira-sans-v10-cyrillic_latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Fira Sans Bold"), local("FiraSans-Bold"), url("../fonts/fira-sans-v10-cyrillic_latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/fira-sans-v10-cyrillic_latin-700.woff2") format("woff2"), url("../fonts/fira-sans-v10-cyrillic_latin-700.woff") format("woff"), url("../fonts/fira-sans-v10-cyrillic_latin-700.ttf") format("truetype"), url("../fonts/fira-sans-v10-cyrillic_latin-700.svg#FiraSans") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/ProximaNova-Regular.eot");
  src: url("../fonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Regular.woff2") format("woff2"), url("../fonts/ProximaNova-Regular.woff") format("woff"), url("../fonts/ProximaNova-Regular.ttf") format("truetype"); }

/*=============================== reset ===============================*/
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  margin: 0;
  color: #000;
  background: #fff;
  font-size: 14px;
  line-height: 1.7em;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  font-family: arial, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

pre {
  *overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type='checkbox'],
input[type='radio'] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto; }

input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

a,
a:visited,
a:hover,
a:focus {
  text-decoration: none;
  outline: none; }

figure {
  margin: 0; }

img {
  vertical-align: middle; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
a,
p,
span,
pre,
code,
strong {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

img {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: middle; }

sub,
sup,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
applet,
object,
iframe,
blockquote,
abbr,
acronym,
address,
big,
cite,
del,
dfn,
em,
ins,
kbd,
q,
s,
samp,
small,
strike,
tt,
var,
b,
u,
i,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
code,
ul ol,
ul ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

textarea {
  outline: none;
  resize: none;
  overflow: auto; }

input,
button,
select {
  outline: none;
  resize: none; }

:focus {
  outline: none; }

button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  border: none; }

input[type='file'] {
  margin: 0;
  padding: 0;
  border: 0; }

.hidden {
  display: none !important; }

.clear_fix {
  display: block; }

.clear_fix:after {
  content: ' ';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

/* Удаляем все анимации и переходы для людей, которые предпочитают их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important; } }

body {
  letter-spacing: normal;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #001A11;
  background: #fff; }

.container {
  max-width: 1720px;
  padding: 0 30px;
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  @media screen and (max-width: 1660px) {
    .container {
      padding: 0 100px; } }
  @media screen and (max-width: 1300px) {
    .container {
      padding: 0 50px; } }
  @media screen and (max-width: 1200px) {
    .container {
      padding: 0 15px; } }
  @media screen and (max-width: 600px) {
    .container {
      padding: 0 5px; } }

.inline-flex {
  display: inline-flex; }

.flex {
  display: flex; }

.flex.jsb, .inline-flex.jsb {
  justify-content: space-between; }

.flex.jfs, .inline-flex.jfs {
  justify-content: flex-start; }

.flex.jfe, .inline-flex.jfe {
  justify-content: flex-end; }

.flex.jc, .inline-flex.jc {
  justify-content: center; }

.flex.ac, .inline-flex.ac {
  align-items: center; }

.flex.afs, .inline-flex.afs {
  align-items: flex-start; }

.flex.afe, .inline-flex.afe {
  align-items: flex-end; }

.flex.fdc, .inline-flex.fdc {
  flex-direction: column; }

.mm-navbar, .mm-panel, .mm-panels {
  background-color: #63B4E9 !important; }

.bold_text {
  font-weight: bold; }

.mtb {
  margin-top: 100px !important; }
  @media screen and (max-width: 1440px) {
    .mtb {
      margin-top: 75px !important; } }
  @media screen and (max-width: 1024px) {
    .mtb {
      margin-top: 40px !important; } }

h1, h2, h3, h4 {
  letter-spacing: normal; }
  @media screen and (max-width: 600px) {
    h1, h2, h3, h4 {
      letter-spacing: .09rem; } }

.pattern_bg {
  position: relative; }
  .pattern_bg:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 500px;
    bottom: 0;
    left: 0;
    background-image: url(../img/svg/pattern.svg); }
    @media screen and (max-width: 1024px) {
      .pattern_bg:after {
        height: 200px; } }

.button {
  margin-bottom: 0;
  padding: 10px 25px;
  width: 300px;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 5px 8px 15px rgba(0, 0, 0, 0.08);
  border: none;
  border-radius: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: normal;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  transition: all 0.5s;
  z-index: 2;
  border-radius: 5px; }
  @media screen and (max-width: 1400px) {
    .button {
      padding-left: 25px; } }
  @media screen and (max-width: 1024px) {
    .button {
      padding-left: 15px; } }
  @media screen and (max-width: 768px) {
    .button {
      width: 242px;
      height: 50px;
      font-size: 14px;
      line-height: 17px; } }
  @media screen and (max-width: 450px) {
    .button {
      margin: auto;
      padding: 10px 22px 10px 17px;
      justify-content: space-between; } }
  @media screen and (max-width: 350px) {
    .button {
      width: 100%; } }
  .button:hover {
    color: #fff; }
  .button:focus {
    color: #fff; }
  .button__transparent {
    color: #63B4E9;
    border: 1px solid #63B4E9;
    justify-content: center;
    border-radius: 5px;
    box-shadow: none; }
    @media screen and (max-width: 767px) {
      .button__transparent {
        width: 152px;
        padding: 6px;
        line-height: 1.2;
        height: 30px;
        margin: 0;
        align-self: flex-end;
        transform: translateY(-9px); } }
    .button__transparent:hover {
      color: #ffffff;
      background-color: #63B4E9;
      border: 1px solid #63B4E9; }
  .button_color_first {
    background-color: #538E4A; }
    .button_color_first:hover {
      background-color: #5dab89; }
  .button_color_second {
    background-color: #7166f1; }
    .button_color_second:hover {
      background-color: #524ba8; }
  .button_popup {
    margin: 25px auto 0; }
  .button_review {
    margin-left: auto;
    margin-right: auto; }
  .button__arrow {
    margin-left: 20px;
    position: relative;
    width: 35px;
    height: 1px;
    background-color: #fff; }
    @media screen and (max-width: 768px) {
      .button__arrow {
        width: 32px;
        height: 1px; } }
    .button__arrow:before {
      content: '';
      position: absolute;
      right: 0;
      top: -2px;
      width: 5px;
      height: 5px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg); }
      @media screen and (max-width: 768px) {
        .button__arrow:before {
          top: -3px;
          width: 7px;
          height: 7px;
          border-top-width: 1px;
          border-right-width: 1px; } }

.social {
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 1200px) {
    .social {
      width: 100px;
      align-self: center; } }
  @media screen and (max-width: 1024px) {
    .social {
      order: 1; } }
  .social__svg {
    fill: #bdbdbd;
    transition: all 0.3s; }
    .social__svg:hover {
      fill: #538E4A; }
    .social__svg_inst {
      width: 28px;
      height: 28px; }
      @media screen and (max-width: 1200px) {
        .social__svg_inst {
          width: 20px;
          height: 20px; } }
    .social__svg_fb {
      width: 36px;
      height: 36px; }
      @media screen and (max-width: 1200px) {
        .social__svg_fb {
          width: 22px;
          height: 22px; } }
    .social__svg_vk {
      width: 39px;
      height: 39px; }
      @media screen and (max-width: 1200px) {
        .social__svg_vk {
          width: 28px;
          height: 28px; } }

.block_header {
  margin-bottom: 50px; }
  @media screen and (max-width: 1440px) {
    .block_header {
      margin-bottom: 30px; } }
  @media screen and (max-width: 600px) {
    .block_header {
      margin-bottom: 20px; } }

.title {
  font-size: 68px;
  font-weight: bold;
  line-height: 1.2;
  color: #001A11; }
  @media screen and (max-width: 1400px) {
    .title {
      font-size: 56px;
      line-height: 56px; } }
  @media screen and (max-width: 1024px) {
    .title {
      font-size: 46px;
      line-height: 46px; } }
  @media screen and (max-width: 768px) {
    .title {
      font-size: 38px;
      line-height: 36px; } }
  @media screen and (max-width: 600px) {
    .title {
      font-size: 32px; } }
  @media screen and (max-width: 450px) {
    .title {
      font-size: 26px; } }

.subtitle {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  color: #001A11; }
  @media screen and (max-width: 1400px) {
    .subtitle {
      font-size: 30px;
      line-height: 42px; } }
  @media screen and (max-width: 1024px) {
    .subtitle {
      font-size: 26px;
      line-height: 36px; } }
  @media screen and (max-width: 768px) {
    .subtitle {
      font-size: 22px;
      line-height: 31px; } }
  @media screen and (max-width: 600px) {
    .subtitle {
      font-size: 18px;
      line-height: 25px; } }
  @media screen and (max-width: 450px) {
    .subtitle {
      font-size: 14px;
      line-height: 20px; } }

.smalltitle {
  font-size: 46px;
  font-weight: 500;
  line-height: 55px;
  letter-spacing: -0.04em;
  color: #001A11; }
  @media screen and (max-width: 1440px) {
    .smalltitle {
      font-size: 44px;
      line-height: 53px;
      letter-spacing: normal; } }
  @media screen and (max-width: 1024px) {
    .smalltitle {
      font-size: 38px;
      line-height: 44px; } }
  @media screen and (max-width: 768px) {
    .smalltitle {
      font-size: 32px;
      line-height: 38px; } }
  @media screen and (max-width: 600px) {
    .smalltitle {
      font-size: 26px;
      line-height: 32px; } }
  @media screen and (max-width: 450px) {
    .smalltitle {
      font-size: 22px;
      line-height: 26px; } }

.light-text {
  font-size: 24px;
  font-weight: 200;
  line-height: 38px; }
  @media screen and (max-width: 1024px) {
    .light-text {
      font-size: 20px;
      line-height: 32px; } }
  @media screen and (max-width: 768px) {
    .light-text {
      font-size: 18px;
      line-height: 26px; } }
  @media screen and (max-width: 600px) {
    .light-text {
      font-size: 16px;
      line-height: 24px; } }
  @media screen and (max-width: 450px) {
    .light-text {
      font-size: 14px;
      line-height: 20px; } }

.page {
  padding: 60px 0 100px; }
  @media screen and (max-width: 1024px) {
    .page {
      padding: 40px 0 60px; } }
  @media screen and (max-width: 600px) {
    .page {
      padding: 15px 0 40px; } }
  .page_no-bottom {
    padding-bottom: 0; }
  .page__title {
    margin-bottom: 35px;
    font-size: 68px;
    font-weight: 500;
    line-height: 82px;
    letter-spacing: -0.04em;
    color: #001A11; }
    @media screen and (max-width: 1440px) {
      .page__title {
        font-size: 56px;
        line-height: 67px; } }
    @media screen and (max-width: 1024px) {
      .page__title {
        margin-bottom: 25px;
        font-size: 46px;
        line-height: 52px; } }
    @media screen and (max-width: 768px) {
      .page__title {
        font-size: 38px;
        line-height: 36px;
        letter-spacing: normal; } }
    @media screen and (max-width: 600px) {
      .page__title {
        margin-bottom: 20px;
        font-size: 32px;
        line-height: 34px; } }
    @media screen and (max-width: 450px) {
      .page__title {
        font-size: 26px;
        line-height: 31px; } }
    .page__title_article {
      margin-bottom: 30px;
      font-size: 46px;
      font-weight: 500;
      line-height: 55px;
      letter-spacing: -0.04em; }
      @media screen and (max-width: 1440px) {
        .page__title_article {
          font-size: 44px;
          line-height: 53px; } }
      @media screen and (max-width: 1024px) {
        .page__title_article {
          font-size: 38px;
          line-height: 42px; } }
      @media screen and (max-width: 768px) {
        .page__title_article {
          font-size: 32px;
          line-height: 38px; } }
      @media screen and (max-width: 600px) {
        .page__title_article {
          font-size: 26px;
          line-height: 32px; } }
      @media screen and (max-width: 450px) {
        .page__title_article {
          font-size: 22px;
          line-height: 26px; } }
  .page__wrapper {
    padding: 50px 80px; }
    @media screen and (max-width: 1440px) {
      .page__wrapper {
        padding: 40px 80px; } }
    @media screen and (max-width: 1024px) {
      .page__wrapper {
        padding: 30px 33px; } }
    @media screen and (max-width: 600px) {
      .page__wrapper {
        padding: 20px 11px; } }
    .page__wrapper_container {
      margin: auto;
      max-width: 1580px; }
      @media screen and (max-width: 1440px) {
        .page__wrapper_container {
          max-width: 1228px; } }
    .page__wrapper_padding {
      padding-left: 0;
      padding-right: 0; }
    .page__wrapper_bg {
      background-color: #EBF7F9; }
    .page__wrapper_margin {
      margin: 50px 0; }
      @media screen and (max-width: 1440px) {
        .page__wrapper_margin {
          margin: 40px 0; } }
      @media screen and (max-width: 1024px) {
        .page__wrapper_margin {
          margin: 30px 0; } }
      @media screen and (max-width: 600px) {
        .page__wrapper_margin {
          margin: 20px 0; } }
  .page__img {
    float: left;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    .page__img_about {
      margin-right: 50px;
      margin-bottom: 40px;
      width: 750px;
      height: 625px; }
      @media screen and (max-width: 1440px) {
        .page__img_about {
          margin-right: 45px;
          margin-bottom: 25px;
          width: 370px;
          height: 300px; } }
      @media screen and (max-width: 1024px) {
        .page__img_about {
          margin-bottom: 12px; } }
      @media screen and (max-width: 768px) {
        .page__img_about {
          margin-bottom: 30px;
          margin-right: 0;
          width: 100%;
          height: 400px; } }
  .page__content {
    font-size: 24px;
    font-weight: 300;
    line-height: 38px;
    letter-spacing: -0.04em;
    color: #001A11; }
    .page__content.quote_margin {
      margin-bottom: 60px; }
    @media screen and (max-width: 768px) {
      .page__content {
        font-size: 20px;
        line-height: 23px;
        letter-spacing: normal; } }
    .page__content_flaot {
      display: inline; }
    .page__content h2 {
      margin-bottom: 60px;
      font-size: 46px;
      font-weight: 500;
      line-height: 55px;
      letter-spacing: -0.04em; }
      @media screen and (max-width: 1440px) {
        .page__content h2 {
          font-size: 44px;
          line-height: 53px;
          letter-spacing: normal; } }
      @media screen and (max-width: 1024px) {
        .page__content h2 {
          margin-bottom: 40px;
          font-size: 38px;
          line-height: 46px; } }
      @media screen and (max-width: 768px) {
        .page__content h2 {
          font-size: 32px;
          line-height: 38px; } }
      @media screen and (max-width: 600px) {
        .page__content h2 {
          margin-bottom: 20px;
          font-size: 26px;
          line-height: 32px; } }
      @media screen and (max-width: 450px) {
        .page__content h2 {
          font-size: 22px;
          line-height: 26px; } }
    .page__content h3 {
      margin-bottom: 20px;
      font-size: 38px;
      font-weight: 500;
      line-height: 46px;
      letter-spacing: normal; }
      @media screen and (max-width: 1440px) {
        .page__content h3 {
          margin-bottom: 30px;
          font-size: 30px;
          line-height: 42px; } }
      @media screen and (max-width: 1024px) {
        .page__content h3 {
          margin-bottom: 20px;
          font-size: 26px;
          line-height: 36px; } }
      @media screen and (max-width: 768px) {
        .page__content h3 {
          font-size: 22px;
          line-height: 31px; } }
      @media screen and (max-width: 600px) {
        .page__content h3 {
          margin-bottom: 10px;
          font-size: 18px;
          line-height: 24px; } }
      @media screen and (max-width: 450px) {
        .page__content h3 {
          font-size: 14px;
          line-height: 20px; } }
    .page__content p:not(:last-of-type) {
      margin-bottom: 30px; }
    .page__content.light-text p {
      font-size: 24px;
      font-weight: 200;
      line-height: 38px; }
      @media screen and (max-width: 1024px) {
        .page__content.light-text p {
          font-size: 20px;
          line-height: 32px; } }
      @media screen and (max-width: 768px) {
        .page__content.light-text p {
          font-size: 18px;
          line-height: 26px; } }
      @media screen and (max-width: 600px) {
        .page__content.light-text p {
          font-size: 16px;
          line-height: 24px; } }
      @media screen and (max-width: 450px) {
        .page__content.light-text p {
          font-size: 14px;
          line-height: 20px; } }
    .page__content ul {
      list-style-type: none; }
    .page__content li {
      margin-bottom: 15px;
      padding-left: 28px;
      position: relative; }
      .page__content li::before {
        content: '';
        position: static;
        display: inline-block;
        width: 7px;
        height: 7px;
        background-color: #3883e8;
        border-radius: 50%;
        transform: translate(0px, -5px);
        margin-right: 17px; }
        @media screen and (max-width: 1024px) {
          .page__content li::before {
            top: 8px; } }
        @media screen and (max-width: 450px) {
          .page__content li::before {
            top: 7px; } }
    .page__content_colorh3 h3 {
      color: #63B4E9; }
    .page__content_upperh3 h3 {
      margin-bottom: 40px;
      font-size: 40px;
      font-weight: 400;
      line-height: 52px;
      letter-spacing: normal;
      text-transform: uppercase; }
      @media screen and (max-width: 1440px) {
        .page__content_upperh3 h3 {
          margin-bottom: 30px;
          font-size: 30px;
          line-height: 42px; } }
      @media screen and (max-width: 1024px) {
        .page__content_upperh3 h3 {
          margin-bottom: 20px;
          font-size: 26px;
          line-height: 36px; } }
      @media screen and (max-width: 768px) {
        .page__content_upperh3 h3 {
          font-size: 22px;
          line-height: 31px; } }
      @media screen and (max-width: 600px) {
        .page__content_upperh3 h3 {
          margin-bottom: 10px;
          font-size: 18px;
          line-height: 24px; } }
      @media screen and (max-width: 450px) {
        .page__content_upperh3 h3 {
          font-size: 14px;
          line-height: 20px; } }
    .page__content_columns {
      column-count: 2;
      column-gap: 60px; }
      @media screen and (max-width: 1024px) {
        .page__content_columns {
          column-gap: 20px; } }
      @media screen and (max-width: 600px) {
        .page__content_columns {
          column-count: 1; } }
    .page__content_blocks {
      flex-wrap: wrap; }
  .page__block {
    width: 48.5%; }
    @media screen and (max-width: 600px) {
      .page__block {
        width: 100%; }
        .page__block:not(:first-child) {
          margin-top: 30px; } }
  .page__color-text {
    font-size: 40px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: normal;
    color: #63B4E9;
    text-align: center;
    text-transform: uppercase; }
    @media screen and (max-width: 1440px) {
      .page__color-text {
        font-size: 30px;
        line-height: 42px; } }
    @media screen and (max-width: 1024px) {
      .page__color-text {
        font-size: 26px;
        line-height: 36px; } }
    @media screen and (max-width: 768px) {
      .page__color-text {
        font-size: 22px;
        line-height: 31px; } }
    @media screen and (max-width: 600px) {
      .page__color-text {
        font-size: 18px;
        line-height: 26px; } }
    @media screen and (max-width: 450px) {
      .page__color-text {
        font-size: 14px;
        line-height: 20px; } }

.about__margin {
  margin-top: 25px;
  margin-bottom: 100px; }
  @media screen and (max-width: 1440px) {
    .about__margin {
      margin-top: 45px;
      margin-bottom: 70px; } }
  @media screen and (max-width: 1024px) {
    .about__margin {
      margin-top: 40px;
      margin-bottom: 40px; } }
  @media screen and (max-width: 600px) {
    .about__margin {
      margin-top: 20px;
      margin-bottom: 30px; } }

.captcha-item {
  display: flex;
  align-items: center;
  justify-content: center; }

body .bvi-body[data-bvi-builtelements=false]:not(.bvi-no-styles, .captcha-item *) iframe[title="reCAPTCHA"] {
  display: block !important; }

/* ******************************* OLD ************************************** */
.breadcrumb {
  list-style: none;
  margin: 0;
  line-height: 24px; }
  @media screen and (max-width: 1024px) {
    .breadcrumb {
      line-height: 19px; } }
  @media screen and (max-width: 600px) {
    .breadcrumb {
      line-height: 14px; } }

.breadcrumb li {
  display: inline-block;
  color: #538E4A;
  margin: 0;
  font-size: 20px; }
  @media screen and (max-width: 1024px) {
    .breadcrumb li {
      font-size: 16px; } }
  @media screen and (max-width: 600px) {
    .breadcrumb li {
      font-size: 12px; } }

.breadcrumb li a {
  color: #232222;
  text-decoration: none; }

.breadcrumb_block {
  padding: 12px 0;
  background-color: #fafafa; }
  @media screen and (max-width: 1024px) {
    .breadcrumb_block {
      padding: 10px 0; } }

.breadcrumb li:not(:first-child)::before {
  content: "/";
  display: inline-block;
  margin: 0px 12px;
  color: #232222; }
  @media screen and (max-width: 600px) {
    .breadcrumb li:not(:first-child)::before {
      margin: 0 8px; } }

.mm-navbar, .mm-panel, .mm-panels {
  background: #63B4E9; }

.mm-listitem:after {
  left: 0; }

.mm-btn_next:after {
  right: 50%; }

@media (max-width: 450px) {
  .mm-btn_next:after {
    margin-right: 5px; } }

/* ************************************************************************************
********************************** пагинцая начало ************************************
************************************************************************************ */
.page_block {
  clear: both;
  text-align: center;
  letter-spacing: normal;
  /*
  margin-top: 60px;

  @include media(1440px) {
    margin-top: 50px;
  }

  @include media(1024px) {
    margin-top: 40px;
  }

  @include media(600px) {
    margin-top: 30px;
  }
  */ }

.page_block ul {
  list-style: none; }

.page_block li {
  display: inline-block;
  color: #9b9b9b;
  font-size: 34px;
  font-weight: 200;
  margin: 0 10px; }

.page_block li.active {
  font-weight: 400;
  color: #63B4E9; }

.page_block li a {
  color: #9b9b9b;
  font-size: 34px;
  text-decoration: none;
  font-weight: 200; }

.page_prev, .page_next {
  width: 60px;
  height: 22px;
  display: block;
  position: relative; }

.page_prev {
  margin-right: 36px; }

.page_next {
  margin-left: 36px; }

.page_prev:before, .page_prev:after, .page_next:after, .page_next:before {
  position: absolute;
  content: ''; }

.page_prev:before, .page_next:before {
  width: 100%;
  height: 1px;
  top: 10px;
  left: 0;
  background-color: #63B4E9; }

.page_prev:after, .page_next:after {
  width: 10px;
  height: 10px;
  border-left: 1px solid #63B4E9;
  border-top: 1px solid #63B4E9;
  transform: rotate(-45deg);
  left: 1px;
  top: 5px; }

.page_next:after {
  border: none;
  border-bottom: 1px solid #63B4E9;
  border-right: 1px solid #63B4E9;
  right: 1px;
  left: auto; }

.page_prev.disabled:after, .page_next.disabled:after {
  border-color: #9b9b9b; }

.page_prev.disabled:before, .page_next.disabled:before {
  background-color: #9b9b9b; }

.circle_page li a {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #cfcfcf; }

.circle_page li.active {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #63B4E9; }

@media screen and (max-width: 600px) {
  .page_block li {
    margin: 0 4px; }
  .page_block li a, .page_block li {
    font-size: 24px; }
  .page_block {
    margin-top: 20px; }
  .page_prev, .page_next {
    width: 40px; }
  .page_prev:before, .page_next:before {
    top: 14px; }
  .page_prev:after, .page_next:after {
    top: 10px;
    width: 8px;
    height: 8px; } }

/* ************************************************************************************
********************************** пагинцая конец *************************************
************************************************************************************ */
/* ************************************************************************************
********************************** OWL OVERRIDE ***************************************
************************************************************************************ */
.owl-dots {
  text-align: center;
  margin-top: 35px; }

.owl-dots button.owl-dot {
  width: 18px;
  height: 18px;
  background: #cfcfcf;
  border-radius: 50%;
  margin-right: 13px; }

.owl-dots button.owl-dot:last-child {
  margin: 0; }

.owl-dots button.owl-dot.active {
  background: #3883e8; }

button.owl-dot:after {
  content: none; }

@media screen and (max-width: 1024px) {
  .owl-next {
    right: 5px; }
  .owl-prev {
    left: 5px; }
  .owl-next,
  .owl-prev {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2) !important;
    top: 0;
    margin-top: -30px; }
  .owl-next:after,
  .owl-prev:after {
    content: none; }
  .owl-next:before,
  .owl-prev:before {
    content: '';
    position: absolute;
    width: 17px;
    height: 17px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg) translateY(-50%);
    top: 45%;
    left: 10px; }
  .owl-prev:before {
    border: 0;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    left: 18px; }
  .owl-nav {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0; } }

@media screen and (max-width: 450px) {
  .owl-dots {
    margin-top: 30px; }
  .owl-dots button.owl-dot {
    width: 14px;
    height: 14px;
    margin-right: 8px; } }

/* ************************************************************************************
*************************************** OWL END ***************************************
************************************************************************************ */
.navigation {
  background-color: #63B4E9; }
  .navigation__wrapper {
    height: 50px; }
    .navigation__wrapper .header__vision {
      display: none; }
      @media screen and (max-width: 700px) {
        .navigation__wrapper .header__vision {
          display: flex; } }
    @media screen and (max-width: 1024px) {
      .navigation__wrapper {
        height: 60px; } }
    @media screen and (max-width: 450px) {
      .navigation__wrapper {
        height: 52px; } }
  .navigation__menu {
    flex-grow: 1; }
    @media screen and (max-width: 1024px) {
      .navigation__menu {
        display: none; } }
  .navigation__list {
    height: 100%;
    display: flex;
    align-items: center; }
    .navigation__list.mm-listview {
      display: block; }
  .navigation__sublist {
    position: absolute;
    left: -9999px;
    width: 270px;
    padding: 16px 22px 22px;
    background-color: #63B4E9;
    opacity: 0;
    transition: opacity 0.5s;
    z-index: 5; }
    .navigation__sublist.mm-listview {
      position: relative;
      left: 0;
      width: 100%;
      padding: 0;
      opacity: 1; }
  .navigation__item.first {
    margin-left: 0; }
  .navigation__item_lvl_1 {
    margin-left: 4.5%;
    position: relative; }
    .navigation__item_lvl_1:hover .navigation__sublist {
      left: 0;
      opacity: 1; }
    .navigation__item_lvl_1.mm-listitem {
      margin-left: 0; }
    @media screen and (max-width: 1660px) {
      .navigation__item_lvl_1 {
        margin-left: 2.5%; } }
    @media screen and (max-width: 1440px) {
      .navigation__item_lvl_1 {
        margin-left: 2.2%; } }
    @media screen and (max-width: 1200px) {
      .navigation__item_lvl_1 {
        margin-left: 2%; } }
  .navigation__item_lvl_2 {
    margin-bottom: 14px; }
    .navigation__item_lvl_2.last {
      margin-bottom: 0; }
    .navigation__item_lvl_2.mm-listitem {
      margin-bottom: 0; }
  .navigation__link {
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    transition: color 0.5s; }
    @media screen and (max-width: 1440px) {
      .navigation__link {
        font-size: 18px;
        line-height: 22px; } }
    @media screen and (max-width: 1200px) {
      .navigation__link {
        font-size: 16px; } }
    .navigation__link:hover {
      color: #538E4A; }
  .navigation__m-logo {
    display: none;
    width: 178px;
    height: 41px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    background-image: url(../img/svg/logoNew.svg); }
    @media screen and (max-width: 1024px) {
      .navigation__m-logo {
        display: block; } }
    @media screen and (max-width: 700px) {
      .navigation__m-logo {
        width: 150px;
        height: 35px; } }
    @media screen and (max-width: 450px) {
      .navigation__m-logo {
        width: 142px;
        height: 34px; } }
  .navigation__m-phone {
    display: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #fff; }
    @media screen and (max-width: 1024px) {
      .navigation__m-phone {
        display: block; } }
    @media screen and (max-width: 700px) {
      .navigation__m-phone {
        width: 35px;
        height: 35px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url(../img/svg/phone_footer.svg);
        text-indent: -9999px; } }
    @media screen and (max-width: 600px) {
      .navigation__m-phone {
        margin-right: 20px;
        margin-left: auto; } }
    @media screen and (max-width: 450px) {
      .navigation__m-phone {
        width: 26px;
        height: 26px;
        display: none; } }
  .navigation__button {
    width: 220px;
    height: 100%;
    padding: 0;
    justify-content: center;
    box-shadow: none;
    font-size: 16px;
    line-height: 19px;
    text-decoration: underline;
    text-underline-offset: 2px;
    border-radius: 0; }
    @media screen and (max-width: 1440px) {
      .navigation__button {
        width: 188px; } }
    @media screen and (max-width: 1024px) {
      .navigation__button {
        width: 194px;
        height: 40px;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px; } }
    @media screen and (max-width: 600px) {
      .navigation__button {
        display: none; } }
  .navigation__hamburger {
    display: none;
    width: 50px;
    height: 35px;
    justify-content: center;
    align-items: center;
    position: relative; }
    @media screen and (max-width: 1024px) {
      .navigation__hamburger {
        display: flex; } }
    @media screen and (max-width: 450px) {
      .navigation__hamburger {
        width: 36px;
        height: 25px; } }
    .navigation__hamburger-add {
      width: 100%;
      height: 5px;
      background-color: #fff;
      border-radius: 3px;
      transition: background-color 0.3s; }
      @media screen and (max-width: 450px) {
        .navigation__hamburger-add {
          height: 3px; } }
      .navigation__hamburger-add:before, .navigation__hamburger-add:after {
        content: '';
        width: 100%;
        height: 5px;
        position: absolute;
        background-color: #fff;
        border-radius: 3px;
        left: 0;
        transition: all 0.5s; }
        @media screen and (max-width: 450px) {
          .navigation__hamburger-add:before, .navigation__hamburger-add:after {
            height: 3px; } }
      .navigation__hamburger-add:before {
        top: 0; }
      .navigation__hamburger-add:after {
        bottom: 0; }
    .navigation__hamburger_active .navigation__hamburger-add {
      background-color: transparent; }
      .navigation__hamburger_active .navigation__hamburger-add:before {
        transform: rotate(45deg);
        top: 14px; }
      .navigation__hamburger_active .navigation__hamburger-add:after {
        transform: rotate(-45deg);
        bottom: 15px; }
      @media screen and (max-width: 450px) {
        .navigation__hamburger_active .navigation__hamburger-add:before {
          top: 11px; }
        .navigation__hamburger_active .navigation__hamburger-add::after {
          bottom: 11px; } }

.header__company-info {
  height: 126px;
  align-items: center; }
  @media screen and (max-width: 1660px) {
    .header__company-info {
      flex-wrap: wrap;
      height: auto;
      padding: 30px 0; } }
  @media screen and (max-width: 1024px) {
    .header__company-info {
      padding: 20px 0;
      align-items: center; } }
  @media screen and (max-width: 700px) {
    .header__company-info {
      display: none; } }
  .header__company-info .one_block {
    /*
            width: 49%;
            */
    display: flex;
    justify-content: space-between;
    /*
            @include media(1660px) {
                width: 100%;
                &:first-child {
                    margin-bottom: 16px;
                }
            }
            */ }

.header__logo {
  width: 230px;
  height: 66px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  background-image: url(../img/svg/logoNew.svg); }
  @media screen and (max-width: 1660px) {
    .header__logo {
      margin-right: 1%; } }
  @media screen and (max-width: 1440px) {
    .header__logo {
      width: 87px;
      height: 45px;
      background-size: cover;
      flex-shrink: 0; } }
  @media screen and (max-width: 1024px) {
    .header__logo {
      display: none; } }

.header__infoblock {
  flex-shrink: 0; }
  .header__infoblock_address {
    max-width: 286px; }
    @media screen and (max-width: 1024px) {
      .header__infoblock_address {
        max-width: 240px; } }
    @media screen and (max-width: 900px) {
      .header__infoblock_address {
        width: 130px; } }
    @media screen and (max-width: 1440px) {
      .header__infoblock_address svg {
        width: 20px !important;
        height: 18px !important; } }
  @media screen and (max-width: 900px) {
    .header__infoblock_worktime .worktime_break {
      display: block; } }

.header__svg {
  margin-top: 4px;
  margin-right: 16px;
  width: 20px;
  height: 20px;
  fill: transparent;
  stroke: #63B4E9;
  flex-shrink: 0; }
  .header__svg:last-of-type {
    width: 31px; }
    @media screen and (max-width: 1440px) {
      .header__svg:last-of-type {
        width: 20px; } }
  @media screen and (max-width: 1660px) {
    .header__svg {
      margin-right: 6px; } }
  @media screen and (max-width: 1440px) {
    .header__svg {
      width: 16px;
      height: 16px; } }
  @media screen and (max-width: 1024px) {
    .header__svg {
      margin-top: 0;
      margin-right: 4px; } }

.header__text {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #3f3f3f; }
  @media screen and (max-width: 1440px) {
    .header__text {
      font-size: 14px;
      line-height: 20px; } }
  .header__text_bold {
    font-weight: 600; }
  .header__text b {
    font-weight: 600; }

.header__social {
  fill: #bdbdbd; }

.header__phone {
  font-size: 22px;
  font-weight: 700;
  line-height: 34px;
  color: #3d3d3d; }
  .header__phone:hover {
    color: #3d3d3d; }
  @media screen and (max-width: 1440px) {
    .header__phone {
      font-size: 16px; } }

.header__link {
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  color: #538E4A;
  border-bottom: 1px solid #538E4A; }
  .header__link:hover {
    color: #63B4E9;
    border-bottom: 1px solid #63B4E9; }
  @media screen and (max-width: 1440px) {
    .header__link {
      font-size: 14px;
      line-height: 17px; } }

.header__search {
  width: 300px;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 1660px) {
    .header__search {
      max-width: 160px; } }
  .header__search .input_wrapper {
    position: relative;
    width: 100%; }
    .header__search .input_wrapper .headerSearchButton {
      width: 24px;
      height: 24px;
      display: block;
      padding: 0;
      background-image: url(../img/svg/search.svg);
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      left: 19px;
      top: 50%;
      transform: translateY(-50%);
      border: none; }
      @media screen and (max-width: 1660px) {
        .header__search .input_wrapper .headerSearchButton {
          left: 8px; } }
  .header__search input {
    padding: 13px 13px 13px 54px;
    font-size: 16px;
    width: 100%;
    height: 50px; }
    @media screen and (max-width: 1660px) {
      .header__search input {
        padding-left: 33px; } }
    @media screen and (max-width: 1440px) {
      .header__search input {
        font-size: 14px; } }
    .header__search input::placeholder {
      color: #ADBAC3; }

.header__vision {
  display: flex;
  align-items: center;
  max-width: 164px;
  cursor: pointer;
  margin-right: 10px; }
  @media screen and (max-width: 1440px) {
    .header__vision {
      max-width: 136px; } }
  @media screen and (max-width: 900px) {
    .header__vision {
      max-width: 124px; } }
  .header__vision_logo {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background-image: url(../img/svg/vision.svg);
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 15px; }
    @media screen and (max-width: 1440px) {
      .header__vision_logo {
        margin-right: 8px; } }
    @media screen and (max-width: 900px) {
      .header__vision_logo {
        width: 32px;
        height: 32px; } }
  .header__vision_text {
    font-size: 16px;
    line-height: 1.4;
    text-decoration: underline;
    text-underline-offset: 2px; }
    @media screen and (max-width: 1440px) {
      .header__vision_text {
        font-size: 12px; } }

.footer {
  position: relative;
  padding: 50px 0;
  background-color: #ffffff; }
  @media screen and (max-width: 1024px) {
    .footer {
      padding: 38px 0; } }
  @media screen and (max-width: 1024px) {
    .footer {
      padding: 38px 0; } }
  @media screen and (max-width: 450px) {
    .footer {
      padding: 10px 0; } }
  .footer:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/assets/templates/img/dots_footer.png);
    opacity: 0.03; }
  .footer__logo {
    width: 275px;
    height: 66px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../img/svg/logoNew.svg); }
    @media screen and (max-width: 1024px) {
      .footer__logo {
        width: 177px;
        height: 42px; } }
    @media screen and (max-width: 600px) {
      .footer__logo {
        width: 78px;
        background-position: center left;
        background-size: auto 100%; } }
  .footer__social {
    align-self: flex-end;
    fill: rgba(255, 255, 255, 0.6); }
    @media screen and (max-width: 1024px) {
      .footer__social {
        order: 0; } }
  .footer__bm {
    display: inline-block;
    width: 103px;
    height: 60px;
    margin-right: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../img/svg/bm_logo.svg); }
    @media screen and (max-width: 1024px) {
      .footer__bm {
        margin-right: 10px; } }
    @media screen and (max-width: 600px) {
      .footer__bm {
        margin-right: 5px;
        width: 75px;
        height: 35px; } }
    @media screen and (max-width: 450px) {
      .footer__bm {
        margin-right: 0; } }
  .footer__copyright {
    display: inline-block; }
    @media screen and (max-width: 450px) {
      .footer__copyright {
        display: none; } }
  .footer__text {
    display: block;
    font-size: 18px;
    font-weight: 200;
    line-height: 24px;
    color: #001A11; }
    @media screen and (max-width: 1024px) {
      .footer__text {
        font-size: 12px;
        line-height: 20px; } }
    @media screen and (max-width: 600px) {
      .footer__text {
        font-size: 11px; } }
  .footer__link {
    color: inherit; }
    .footer__link:hover {
      color: inherit; }
  .footer__description {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.price__header {
  margin-bottom: 47px;
  padding-bottom: 23px;
  padding-right: 30px;
  position: relative;
  border-bottom: 3px solid rgba(56, 131, 232, 0.2);
  cursor: pointer; }
  @media screen and (max-width: 1440px) {
    .price__header {
      margin-bottom: 51px;
      padding-bottom: 21px; } }
  @media screen and (max-width: 1024px) {
    .price__header {
      margin-bottom: 30px;
      padding-bottom: 20px; } }
  @media screen and (max-width: 600px) {
    .price__header {
      margin-bottom: 18px;
      padding-bottom: 11px; } }
  .price__header::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 30px;
    height: 3px;
    background-color: #63B4E9; }
  .price__header::after {
    content: '';
    position: absolute;
    top: 18px;
    right: 3px;
    width: 18px;
    height: 18px;
    border-bottom: 4px solid #63B4E9;
    border-left: 4px solid #63B4E9;
    transform: rotate(-45deg); }
    @media screen and (max-width: 1440px) {
      .price__header::after {
        top: 13px; } }
    @media screen and (max-width: 1024px) {
      .price__header::after {
        top: 5px; } }
    @media screen and (max-width: 600px) {
      .price__header::after {
        top: 5px;
        width: 8px;
        height: 8px;
        border-width: 2px; } }
  .price__header_active::after {
    top: 14px;
    border: none;
    border-top: 4px solid #bdbdbd;
    border-right: 4px solid #bdbdbd; }
    @media screen and (max-width: 1440px) {
      .price__header_active::after {
        top: 11px; } }
    @media screen and (max-width: 1024px) {
      .price__header_active::after {
        top: 7px; } }
    @media screen and (max-width: 600px) {
      .price__header_active::after {
        top: 5px;
        border-width: 2px; } }
  .price__header_active .price__table {
    display: block; }

.price__title {
  font-size: 40px;
  font-weight: 400;
  line-height: 52px;
  color: #63B4E9;
  text-transform: uppercase; }
  @media screen and (max-width: 1440px) {
    .price__title {
      font-size: 30px;
      line-height: 42px; } }
  @media screen and (max-width: 1024px) {
    .price__title {
      font-size: 22px;
      line-height: 31px; } }
  @media screen and (max-width: 600px) {
    .price__title {
      font-size: 14px;
      line-height: 20px; } }

.price__masters {
  display: block;
  font-size: 26px;
  font-weight: 300;
  line-height: 34px;
  letter-spacing: -0.04em; }
  @media screen and (max-width: 1440px) {
    .price__masters {
      font-size: 24px;
      line-height: 34px; } }
  @media screen and (max-width: 1024px) {
    .price__masters {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: normal; } }
  @media screen and (max-width: 600px) {
    .price__masters {
      font-size: 14px;
      line-height: 20px; } }
  .price__masters_name {
    font-weight: 400; }

.price__table {
  margin-bottom: 70px;
  display: none; }
  @media screen and (max-width: 1024px) {
    .price__table {
      margin-bottom: 30px; } }

.price__row {
  padding: 8px 30px;
  min-height: 65px; }
  @media screen and (max-width: 1024px) {
    .price__row {
      min-height: 50px; } }
  @media screen and (max-width: 600px) {
    .price__row {
      padding: 10px;
      min-height: 36px; } }
  .price__row:nth-child(2n+1) {
    background-color: #fafafa; }
  .price__row_header {
    background-color: #63B4E9 !important;
    font-size: 24px;
    font-weight: 500;
    line-height: 52px;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff; }
    @media screen and (max-width: 1440px) {
      .price__row_header {
        line-height: 42px; } }
    @media screen and (max-width: 1024px) {
      .price__row_header {
        font-size: 20px;
        line-height: 30px; } }
    @media screen and (max-width: 600px) {
      .price__row_header {
        font-size: 16px;
        line-height: 20px; } }
    @media screen and (max-width: 450px) {
      .price__row_header {
        font-size: 14px; } }

.price__name {
  flex-basis: 53%;
  flex-shrink: 0; }
  @media screen and (max-width: 450px) {
    .price__name {
      flex-basis: 40%; } }
  .price__name_item {
    font-size: 20px;
    font-weight: 300;
    line-height: 56px; }
    @media screen and (max-width: 1024px) {
      .price__name_item {
        font-size: 14px; } }
    @media screen and (max-width: 600px) {
      .price__name_item {
        font-size: 12px;
        line-height: 36px; } }

.price__cost {
  flex-basis: 24%;
  flex-shrink: 0;
  text-align: center; }
  @media screen and (max-width: 450px) {
    .price__cost {
      flex-basis: 35%; } }
  .price__cost_item {
    font-size: 26px;
    font-weight: 300;
    line-height: 22px; }
    @media screen and (max-width: 1024px) {
      .price__cost_item {
        font-size: 20px; } }
    @media screen and (max-width: 600px) {
      .price__cost_item {
        font-size: 12px;
        line-height: 16px; } }
  .price__cost_bold {
    font-weight: 400; }

.price__order {
  flex-basis: 21%;
  flex-shrink: 0;
  text-align: right; }
  @media screen and (max-width: 450px) {
    .price__order {
      flex-basis: 25%; } }
  .price__order_item {
    font-size: 20px;
    font-weight: 300;
    line-height: 22px;
    color: #538E4A;
    text-decoration: underline; }
    @media screen and (max-width: 1440px) {
      .price__order_item {
        font-size: 20px; } }
    @media screen and (max-width: 1024px) {
      .price__order_item {
        font-size: 16px; } }
    @media screen and (max-width: 600px) {
      .price__order_item {
        font-size: 12px;
        line-height: 16px; } }
    .price__order_item .js_popup_order {
      cursor: pointer; }

.price_options .price__name {
  font-size: 26px;
  font-weight: 300; }
  @media screen and (max-width: 1440px) {
    .price_options .price__name {
      font-size: 20px; } }
  @media screen and (max-width: 1024px) {
    .price_options .price__name {
      font-size: 18px; } }
  @media screen and (max-width: 600px) {
    .price_options .price__name {
      font-size: 12px; } }

.price_options .price__cost {
  font-size: 26px;
  font-weight: 400;
  text-align: right; }
  @media screen and (max-width: 1024px) {
    .price_options .price__cost {
      font-size: 22px; } }
  @media screen and (max-width: 600px) {
    .price_options .price__cost {
      font-size: 12px; } }

.advantages {
  padding: 20px 0 50px; }
  @media screen and (max-width: 1440px) {
    .advantages {
      padding: 50px 0 25px; } }
  @media screen and (max-width: 1024px) {
    .advantages {
      padding: 40px 0 20px; } }
  @media screen and (max-width: 1024px) {
    .advantages__wrapper {
      flex-wrap: wrap; } }
  .advantages__unit {
    width: 190px;
    padding: 30px 10px 0; }
    @media screen and (max-width: 1024px) {
      .advantages__unit:nth-child(n+4) {
        margin-top: 45px; } }
    @media screen and (max-width: 1024px) {
      .advantages__unit {
        width: 26%;
        min-width: 175px; } }
    @media screen and (max-width: 600px) {
      .advantages__unit {
        width: 100%; } }
    @media screen and (max-width: 600px) {
      .advantages__unit {
        min-width: 140px; } }
  .advantages__icon {
    position: relative;
    margin: 0 auto 40px;
    width: 125px;
    height: 125px;
    box-shadow: 0 28px 21px rgba(140, 204, 217, 0.1);
    border-radius: 50%;
    background-color: #fff; }
    @media screen and (max-width: 1024px) {
      .advantages__icon {
        margin: 0 auto 30px; } }
    @media screen and (max-width: 768px) {
      .advantages__icon {
        width: 110px;
        height: 110px; } }
    @media screen and (max-width: 450px) {
      .advantages__icon {
        margin: 0 auto 20px;
        width: 90px;
        height: 90px; } }
    .advantages__icon:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 50%;
      box-shadow: 0 0 50px 0 rgba(0, 90, 255, 0.5);
      transition: all .5s; }
      @media screen and (max-width: 600px) {
        .advantages__icon:before {
          box-shadow: 0 0 30px 0 rgba(0, 90, 255, 0.5); } }
    .advantages__icon:hover:before {
      opacity: 1; }
  .advantages__text {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #001A11;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .advantages__text {
        font-size: 14px;
        line-height: 20px; } }
  @media screen and (max-width: 600px) {
    .advantages__img {
      width: auto !important; } }
  @media screen and (max-width: 600px) {
    .advantages .owl-nav {
      top: 44%; } }
  @media screen and (max-width: 600px) {
    .advantages .owl-next,
    .advantages .owl-prev {
      width: 20px;
      height: 30px;
      margin-top: -15px;
      background: 0 0 !important; } }
  @media screen and (max-width: 600px) {
    .advantages .owl-next:before {
      left: auto;
      right: 12px;
      border-color: #d0d0d0; } }
  @media screen and (max-width: 600px) {
    .advantages .owl-prev:before {
      left: 0;
      border-color: #d0d0d0; } }

.slider + .advantages {
  margin-top: 50px; }

.banner {
  position: relative;
  min-height: 460px;
  height: 460px;
  padding: 85px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media screen and (max-width: 1440px) {
    .banner {
      background-size: cover; } }
  @media screen and (max-width: 1024px) {
    .banner {
      min-height: 385px;
      height: 385px;
      padding: 65px 0; } }
  @media screen and (max-width: 600px) {
    .banner {
      min-height: 270px;
      height: 270px;
      padding: 45px 0; } }
  @media screen and (max-width: 450px) {
    .banner {
      min-height: 200px;
      height: 200px;
      padding: 20px 0; } }
  .banner_inner_page .container {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .banner_inner_page .container p {
      font-size: 24px; }
  .banner_layer::before {
    content: '';
    position: absolute;
    top: 0;
    width: 80%;
    height: 100%; }
  .banner_layer::before {
    left: 0;
    background: linear-gradient(90.05deg, #EBF7F9 49.98%, rgba(235, 247, 249, 0) 101.34%); }
    @media screen and (max-width: 1440px) {
      .banner_layer::before {
        width: 100%; } }
    @media screen and (max-width: 767px) {
      .banner_layer::before {
        background: linear-gradient(90.05deg, #ebf7f9 59.98%, rgba(235, 247, 249, 0) 141.34%); } }
  .banner__info {
    max-width: 480px;
    font-weight: 500;
    color: #001A11; }
    @media screen and (max-width: 600px) {
      .banner__info {
        max-width: 380px; } }
    @media screen and (max-width: 450px) {
      .banner__info {
        max-width: 100%; } }
  .banner__pretitle {
    display: block;
    font-size: 30px;
    line-height: 44px;
    font-weight: bold; }
    @media screen and (max-width: 1024px) {
      .banner__pretitle {
        max-width: 255px;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px; } }
    @media screen and (max-width: 600px) {
      .banner__pretitle {
        font-size: 16px; } }
    @media screen and (max-width: 450px) {
      .banner__pretitle {
        max-width: 190px;
        font-size: 14px; } }
  .banner__title {
    font-size: 40px;
    line-height: 54px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px; }
    @media screen and (max-width: 1024px) {
      .banner__title {
        font-size: 30px;
        line-height: 40px; } }
    @media screen and (max-width: 600px) {
      .banner__title {
        font-size: 26px;
        line-height: 36px; } }
    @media screen and (max-width: 450px) {
      .banner__title {
        font-size: 24px;
        line-height: 32px; } }
  .banner__text {
    display: block;
    font-size: 18px;
    line-height: 30px; }
    @media screen and (max-width: 1024px) {
      .banner__text {
        margin-top: 20px;
        font-size: 16px;
        line-height: 24px; } }
    @media screen and (max-width: 600px) {
      .banner__text {
        font-size: 14px;
        line-height: 20px; } }
    @media screen and (max-width: 450px) {
      .banner__text {
        line-height: 17px; } }
  .banner__upper-text {
    margin-bottom: 60px;
    max-width: 1300px;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -0.04em;
    color: #fff;
    text-transform: uppercase; }
    @media screen and (max-width: 1440px) {
      .banner__upper-text {
        margin-bottom: 45px;
        max-width: 980px;
        font-size: 30px;
        line-height: 44px; } }
    @media screen and (max-width: 1024px) {
      .banner__upper-text {
        font-size: 26px;
        line-height: 36px;
        letter-spacing: normal; } }
    @media screen and (max-width: 600px) {
      .banner__upper-text {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 22px; } }
  .banner__button {
    margin-top: 30px;
    border-radius: 5px;
    box-shadow: none; }
    @media screen and (max-width: 1024px) {
      .banner__button {
        margin-top: 40px; } }
    @media screen and (max-width: 600px) {
      .banner__button {
        margin-top: 20px; } }

.services {
  padding: 70px 0 50px; }
  @media screen and (max-width: 1440px) {
    .services {
      padding: 100px 0 50px; } }
  @media screen and (max-width: 1024px) {
    .services {
      padding: 40px 0 57px; } }
  @media screen and (max-width: 600px) {
    .services {
      padding: 20px 0; } }
  .services__header {
    margin-bottom: 95px; }
    @media screen and (max-width: 1440px) {
      .services__header {
        margin-bottom: 60px; } }
    @media screen and (max-width: 1024px) {
      .services__header {
        margin-bottom: 40px; } }
    @media screen and (max-width: 600px) {
      .services__header {
        margin-bottom: 30px; } }
  .services__button {
    margin-top: 40px; }
    @media screen and (max-width: 1440px) {
      .services__button {
        margin-top: 25px; } }
    @media screen and (max-width: 1024px) {
      .services__button {
        margin-top: 15px; } }
    @media screen and (max-width: 600px) {
      .services__button {
        display: none; } }
  .services__wrapper {
    flex-wrap: wrap; }
    @media screen and (max-width: 800px) {
      .services__wrapper {
        flex-direction: column; } }
  .services__unit {
    width: 48.5%; }
    @media screen and (max-width: 800px) {
      .services__unit {
        width: 100%; } }
    @media screen and (max-width: 600px) {
      .services__unit {
        flex-direction: column;
        align-items: center; } }
    .services__unit:nth-child(n+3) {
      margin-top: 85px; }
      @media screen and (max-width: 1440px) {
        .services__unit:nth-child(n+3) {
          margin-top: 125px; } }
    @media screen and (max-width: 800px) {
      .services__unit:not(:first-child) {
        margin-top: 67px; } }
    @media screen and (max-width: 600px) {
      .services__unit:not(:first-child) {
        margin-top: 45px; } }
  .services__foto-link {
    align-self: baseline;
    margin-right: 40px; }
    @media screen and (max-width: 1024px) {
      .services__foto-link {
        margin-right: 20px; } }
    @media screen and (max-width: 600px) {
      .services__foto-link {
        margin-right: 0;
        margin-bottom: 10px;
        align-self: unset; } }
  .services__view {
    position: relative; }
  .services__img {
    width: 300px; }
    @media screen and (max-width: 1440px) {
      .services__img {
        width: 222px; } }
    @media screen and (max-width: 1024px) {
      .services__img {
        width: 205px; } }
    @media screen and (max-width: 600px) {
      .services__img {
        width: 100%; } }
  .services__sub-img {
    position: absolute;
    top: 110px;
    left: 140px;
    width: 183px; }
    @media screen and (max-width: 1440px) {
      .services__sub-img {
        top: 84px;
        left: 100px;
        width: 136px; } }
    @media screen and (max-width: 1024px) {
      .services__sub-img {
        top: 105px;
        left: 120px;
        width: 96px; } }
    @media screen and (max-width: 600px) {
      .services__sub-img {
        display: none !important; } }
  .services__info {
    position: relative; }
  .services__title {
    margin-bottom: 35px;
    display: block;
    font-size: 34px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: -0.04em;
    color: #63B4E9; }
    @media screen and (max-width: 1440px) {
      .services__title {
        margin-bottom: 20px;
        font-size: 30px;
        line-height: 33px;
        letter-spacing: normal; } }
    @media screen and (max-width: 1024px) {
      .services__title {
        font-size: 24px; } }
    @media screen and (max-width: 768px) {
      .services__title {
        font-size: 20px;
        line-height: 38px; } }
    .services__title:hover {
      color: #63B4E9; }
  .services__text {
    margin-bottom: 40px;
    display: block;
    font-size: 24px;
    font-weight: 300;
    line-height: 38px;
    letter-spacing: -0.04em;
    color: #001A11; }
    @media screen and (max-width: 1024px) {
      .services__text {
        font-size: 18px;
        line-height: 26px; } }
    @media screen and (max-width: 768px) {
      .services__text {
        font-size: 16px;
        line-height: 21px;
        letter-spacing: normal; } }
    @media screen and (max-width: 450px) {
      .services__text {
        font-size: 14px;
        line-height: 20px; } }
  .services__link {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: #538E4A;
    text-decoration: underline;
    transition: all 0.5s;
    text-underline-offset: 2px;
    text-decoration-thickness: 1px; }
    @media screen and (max-width: 1024px) {
      .services__link {
        font-size: 18px; } }
    @media screen and (max-width: 768px) {
      .services__link {
        font-size: 16px; } }
    .services__link:hover {
      color: #5dab89;
      text-decoration: underline;
      text-decoration-thickness: 1px; }

@media screen and (max-width: 600px) {
  .page .services__text {
    margin-bottom: 0; }
  .page .services__link {
    display: none; } }

.catalog {
  position: relative;
  padding: 70px 0 80px;
  background-color: #EBF7F9; }
  @media screen and (max-width: 1440px) {
    .catalog {
      padding: 50px 0; } }
  @media screen and (max-width: 1024px) {
    .catalog {
      padding: 30px 0 40px; } }
  @media screen and (max-width: 600px) {
    .catalog {
      padding: 30px 0 25px; } }
  .catalog__header {
    margin-bottom: 50px; }
    @media screen and (max-width: 1440px) {
      .catalog__header {
        margin-bottom: 30px; } }
    @media screen and (max-width: 600px) {
      .catalog__header {
        margin-bottom: 20px; } }
  .catalog__wrapper {
    flex-wrap: wrap; }
  .catalog__unit {
    position: relative;
    width: 31.5%;
    min-height: 533px;
    padding: 20px 20px 45px;
    background-color: #fff;
    z-index: 1; }
    @media screen and (max-width: 1440px) {
      .catalog__unit {
        min-height: 615px;
        padding: 20px 15px 65px; } }
    @media screen and (max-width: 1024px) {
      .catalog__unit {
        min-height: 325px;
        padding: 10px 10px 30px; } }
    @media screen and (max-width: 600px) {
      .catalog__unit {
        width: 100%;
        min-height: 240px;
        padding-bottom: 25px; } }
    .catalog__unit:nth-child(n+4) {
      margin-top: 35px; }
      @media screen and (max-width: 1440px) {
        .catalog__unit:nth-child(n+4) {
          margin-top: 50px; } }
      @media screen and (max-width: 1024px) {
        .catalog__unit:nth-child(n+4) {
          margin-top: 30px; } }
  .catalog__img {
    margin-bottom: 30px;
    width: 100%;
    height: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
    @media screen and (max-width: 1440px) {
      .catalog__img {
        height: 264px; } }
    @media screen and (max-width: 1024px) {
      .catalog__img {
        margin-bottom: 20px;
        height: 126px; } }
    @media screen and (max-width: 600px) {
      .catalog__img {
        margin-bottom: 10px;
        height: 77px; } }
  .catalog__name {
    margin-bottom: 13px;
    font-size: 30px;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: -0.04em;
    color: #001A11;
    text-align: center; }
    @media screen and (max-width: 1440px) {
      .catalog__name {
        margin-bottom: 20px;
        font-size: 26px;
        line-height: 22px; } }
    @media screen and (max-width: 1024px) {
      .catalog__name {
        font-size: 20px; } }
    @media screen and (max-width: 768px) {
      .catalog__name {
        margin-bottom: 10px;
        font-size: 14px; } }
    .catalog__name:hover {
      color: #63B4E9; }
  .catalog__price {
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: #001A11; }
    @media screen and (max-width: 1440px) {
      .catalog__price {
        margin-bottom: 15px;
        font-size: 26px; } }
    @media screen and (max-width: 1024px) {
      .catalog__price {
        font-size: 20px; } }
    @media screen and (max-width: 768px) {
      .catalog__price {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 26px; } }
    @media screen and (max-width: 450px) {
      .catalog__price {
        line-height: 22px; } }
  .catalog__cost {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px; }
    @media screen and (max-width: 1440px) {
      .catalog__cost {
        font-size: 26px; } }
    @media screen and (max-width: 1024px) {
      .catalog__cost {
        font-size: 20px;
        line-height: 32px; } }
    @media screen and (max-width: 768px) {
      .catalog__cost {
        font-size: 14px;
        line-height: 26px; } }
    @media screen and (max-width: 450px) {
      .catalog__cost {
        line-height: 22px; } }
  .catalog__exist {
    margin-bottom: 110px;
    font-size: 24px;
    font-weight: 300;
    line-height: 50px;
    letter-spacing: -0.04em;
    color: #538E4A; }
    @media screen and (max-width: 1440px) {
      .catalog__exist {
        margin-bottom: 100px;
        font-size: 26px;
        line-height: 44px; } }
    @media screen and (max-width: 1024px) {
      .catalog__exist {
        margin-bottom: 70px;
        font-size: 20px;
        line-height: 34px; } }
    @media screen and (max-width: 768px) {
      .catalog__exist {
        font-size: 14px;
        line-height: 26px; } }
    @media screen and (max-width: 600px) {
      .catalog__exist {
        margin-bottom: 50px; } }
    @media screen and (max-width: 450px) {
      .catalog__exist {
        font-size: 12px;
        line-height: 22px; } }
    .catalog__exist_no {
      color: #c3c3c3; }
  @media screen and (max-width: 600px) {
    .catalog__button {
      display: none; } }
  .catalog__button_order {
    position: absolute;
    bottom: 45px;
    left: 10%;
    width: 80%;
    height: 68px;
    justify-content: center;
    font-size: 20px;
    line-height: 24px;
    border-radius: 5px;
    box-shadow: none; }
    @media screen and (max-width: 1440px) {
      .catalog__button_order {
        bottom: 65px;
        max-width: 340px;
        height: 72px; } }
    @media screen and (max-width: 1200px) {
      .catalog__button_order {
        width: 86%;
        font-size: 20px;
        line-height: 24px;
        left: inherit; } }
    @media screen and (max-width: 1024px) {
      .catalog__button_order {
        bottom: 30px;
        width: 215px;
        height: 50px;
        font-size: 14px;
        line-height: 17px; } }
    @media screen and (max-width: 768px) {
      .catalog__button_order {
        width: 90%; } }
    @media screen and (max-width: 600px) {
      .catalog__button_order {
        bottom: 28px;
        width: 120px;
        height: 36px;
        font-size: 12px;
        line-height: 15px; } }
  .catalog__button_mobile {
    display: none; }
    @media screen and (max-width: 600px) {
      .catalog__button_mobile {
        display: flex;
        margin: 30px auto 0;
        left: calc((100% - 120px)/2); } }

@media screen and (max-width: 600px) {
  .page .catalog__unit {
    width: 46%; }
    .page .catalog__unit:nth-child(n+3) {
      margin-top: 20px; } }

.gallery {
  padding: 120px 0; }
  @media screen and (max-width: 1440px) {
    .gallery {
      padding: 50px 0 120px; } }
  @media screen and (max-width: 1024px) {
    .gallery {
      padding: 50px 0 55px; } }
  @media screen and (max-width: 600px) {
    .gallery {
      padding: 40px 0 30px; } }
  .gallery__header {
    margin-bottom: 45px; }
    @media screen and (max-width: 1440px) {
      .gallery__header {
        margin-bottom: 50px; } }
    @media screen and (max-width: 1028px) {
      .gallery__header {
        margin-bottom: 30px; } }
    @media screen and (max-width: 600px) {
      .gallery__header {
        margin-bottom: 20px; } }
  @media screen and (max-width: 600px) {
    .gallery__button {
      display: none; } }
  .gallery__item {
    display: block;
    position: relative;
    overflow: hidden;
    transition: 0.5s;
    cursor: pointer; }
    .gallery__item.docs_item {
      margin-top: 0 !important; }
      .gallery__item.docs_item .gallery__name {
        height: 120px;
        padding-right: 20px; }
      .gallery__item.docs_item:hover .gallery__name {
        transform: translateY(-120px); }
    .gallery__item:last-child {
      margin-top: 20px; }
    .gallery__item:first-child {
      margin-top: 0; }
    .gallery__item:hover .gallery__hover {
      opacity: 1; }
    .gallery__item:hover .gallery__name {
      transform: translateY(-60px); }
  .gallery__img {
    width: 100%; }
  .gallery__hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.24);
    transition: 0.3s; }
  .gallery__loupe {
    position: absolute;
    right: 10px;
    top: 15px;
    display: block;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.32);
    background-image: url(../img/loupe.png);
    background-position: center;
    background-repeat: no-repeat; }
  .gallery__name {
    position: absolute;
    top: 100%;
    left: 0;
    padding: 15px 0 15px 24px;
    width: 100%;
    font-weight: 200;
    font-style: italic;
    letter-spacing: normal;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.37);
    box-sizing: border-box;
    transition: 0.6s; }
  .gallery .owl-dots {
    margin-top: 60px; }
    @media screen and (max-width: 1024px) {
      .gallery .owl-dots {
        margin-top: 40px; } }
    @media screen and (max-width: 600px) {
      .gallery .owl-dots {
        margin-top: 30px; } }

.page_gallery .gallery__wrapper {
  flex-wrap: wrap; }
  .page_gallery .gallery__wrapper::after {
    content: '';
    width: 24%; }
    @media screen and (max-width: 1024px) {
      .page_gallery .gallery__wrapper::after {
        width: 32%; } }
  .page_gallery .gallery__wrapper::before {
    content: '';
    width: 24%;
    order: 1; }
    @media screen and (max-width: 1024px) {
      .page_gallery .gallery__wrapper::before {
        width: 0%; } }

.page_gallery .gallery__item {
  width: 24%;
  margin-bottom: 3%; }
  @media screen and (max-width: 1024px) {
    .page_gallery .gallery__item {
      width: 32%; } }
  @media screen and (max-width: 600px) {
    .page_gallery .gallery__item {
      width: 47%; } }
  .page_gallery .gallery__item:hover {
    transform: scale(1.1);
    z-index: 2; }
  .page_gallery .gallery__item:nth-child(n+5) {
    margin-top: 20px; }
  @media screen and (max-width: 1024px) {
    .page_gallery .gallery__item:nth-child(n+4) {
      margin-top: 20px; } }
  @media screen and (max-width: 600px) {
    .page_gallery .gallery__item:nth-child(n+3) {
      margin-top: 20px; } }

.service .gallery {
  padding: 0; }

.service__header {
  margin-bottom: 77px;
  flex-wrap: wrap; }
  @media screen and (max-width: 600px) {
    .service__header {
      margin-bottom: 20px; } }

.service__img {
  width: 44%;
  height: 325px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  .service__img.specialist {
    background-position: center top; }
  @media screen and (max-width: 1440px) {
    .service__img {
      width: 46%;
      height: 258px; } }
  @media screen and (max-width: 1028px) {
    .service__img {
      margin-bottom: 33px;
      width: 100%;
      height: 265px; } }

.service__info {
  width: 49%; }
  @media screen and (max-width: 1028px) {
    .service__info {
      width: 100%; } }

.service__spec_surname {
  display: block;
  font-size: 52px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -.04em;
  color: #63b4e9; }

.service__spec_name, .service__spec_middlename {
  display: block;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: -.04em;
  color: #001a11; }

.service__title {
  margin-bottom: 40px;
  font-size: 38px;
  font-weight: 500;
  line-height: 46px; }
  @media screen and (max-width: 1440px) {
    .service__title {
      margin-bottom: 20px;
      font-size: 34px;
      line-height: 41px;
      letter-spacing: -0.04em; } }
  @media screen and (max-width: 1024px) {
    .service__title {
      font-size: 32px;
      line-height: 38px; } }
  @media screen and (max-width: 768px) {
    .service__title {
      font-size: 30px;
      line-height: 36px; } }
  @media screen and (max-width: 600px) {
    .service__title {
      margin-bottom: 10px;
      font-size: 22px;
      line-height: 27px; } }
  @media screen and (max-width: 450px) {
    .service__title {
      font-size: 16px;
      line-height: 19px; } }

.service__text {
  display: block;
  font-size: 28px;
  font-weight: 300;
  line-height: 42px;
  letter-spacing: -0.04em; }
  @media screen and (max-width: 1440px) {
    .service__text {
      font-size: 28px;
      line-height: 42px; } }
  @media screen and (max-width: 600px) {
    .service__text {
      font-size: 20px;
      line-height: 32px; } }
  @media screen and (max-width: 450px) {
    .service__text {
      font-size: 16px;
      line-height: 24px; } }

.service__cost {
  font-weight: 500; }

.service__link {
  display: block;
  font-size: 24px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.04em;
  color: #63B4E9;
  text-decoration: underline;
  cursor: pointer; }
  @media screen and (max-width: 600px) {
    .service__link {
      font-size: 20px;
      line-height: 40px; } }
  @media screen and (max-width: 450px) {
    .service__link {
      font-size: 16px;
      line-height: 32px; } }
  .service__link:hover {
    color: #63B4E9;
    text-decoration: underline; }

.facts {
  padding: 100px 0 80px; }
  @media screen and (max-width: 1440px) {
    .facts {
      padding: 60px 0 70px; } }
  @media screen and (max-width: 1024px) {
    .facts {
      padding: 40px 0 50px; } }
  @media screen and (max-width: 600px) {
    .facts {
      padding: 20px 0 35px; } }
  .facts__header {
    margin-bottom: 25px;
    font-size: 46px;
    font-weight: 500;
    line-height: 55px;
    letter-spacing: -0.04em; }
    @media screen and (max-width: 1440px) {
      .facts__header {
        margin-bottom: 40px;
        font-size: 44px;
        line-height: 53px;
        letter-spacing: normal; } }
    @media screen and (max-width: 1024px) {
      .facts__header {
        margin-bottom: 35px;
        font-size: 32px;
        line-height: 38px; } }
    @media screen and (max-width: 600px) {
      .facts__header {
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 26px; } }
  .facts__wrapper {
    flex-wrap: wrap; }
  .facts__item {
    width: 47%; }
    @media screen and (max-width: 1024px) {
      .facts__item {
        width: 100%; } }
    .facts__item:nth-child(n+3) {
      margin-top: 60px; }
    @media screen and (max-width: 1024px) {
      .facts__item:nth-child(n+2) {
        margin-top: 40px; } }
    @media screen and (max-width: 600px) {
      .facts__item:nth-child(n+2) {
        margin-top: 35px; } }
  .facts__title {
    margin-bottom: 35px;
    font-size: 40px;
    font-weight: 400;
    line-height: 52px;
    text-transform: uppercase; }
    @media screen and (max-width: 1440px) {
      .facts__title {
        margin-bottom: 20px;
        font-size: 30px;
        line-height: 42px; } }
    @media screen and (max-width: 1024px) {
      .facts__title {
        margin-bottom: 15px;
        font-size: 22px;
        line-height: 31px; } }
    @media screen and (max-width: 600px) {
      .facts__title {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 20px; } }
  .facts__text {
    line-height: 34px;
    letter-spacing: -0.04em; }
    @media screen and (max-width: 1440px) {
      .facts__text {
        line-height: 34px; } }
    @media screen and (max-width: 1024px) {
      .facts__text {
        line-height: 28px; } }
    @media screen and (max-width: 768px) {
      .facts__text {
        line-height: 23px;
        letter-spacing: normal; } }
    @media screen and (max-width: 450px) {
      .facts__text {
        line-height: 20px; } }

.product__subtitle {
  margin-bottom: 10px;
  display: block;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.04em; }
  @media screen and (max-width: 1440px) {
    .product__subtitle {
      font-size: 28px;
      line-height: 34px; } }
  @media screen and (max-width: 1024px) {
    .product__subtitle {
      font-size: 26px;
      line-height: 31px; } }
  @media screen and (max-width: 600px) {
    .product__subtitle {
      font-size: 24px;
      line-height: 29px; } }

.product__header {
  margin-bottom: 70px; }
  @media screen and (max-width: 1024px) {
    .product__header {
      margin-bottom: 60px;
      flex-direction: column; } }
  @media screen and (max-width: 600px) {
    .product__header {
      margin-bottom: 40px; } }

.product__view {
  width: 47%;
  flex-wrap: wrap;
  align-self: baseline; }
  @media screen and (max-width: 1440px) {
    .product__view {
      width: 38%;
      margin-right: 4.5%; } }
  @media screen and (max-width: 1024px) {
    .product__view {
      width: 100%;
      margin-right: 0; } }

.product__img {
  width: 19.5%;
  height: 100px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer; }
  @media screen and (max-width: 1440px) {
    .product__img {
      height: 98px; } }
  @media screen and (max-width: 600px) {
    .product__img {
      height: 43px; } }
  .product__img:nth-child(n+2) {
    margin-top: 25px;
    margin-right: 0.625%; }
    @media screen and (max-width: 600px) {
      .product__img:nth-child(n+2) {
        margin-top: 15px; } }
  .product__img:nth-child(5n+1) {
    margin-right: 0; }
  .product__img:nth-child(n+7) {
    margin-top: 10px; }
  .product__img_first {
    width: 100%;
    height: 520px; }
    @media screen and (max-width: 1440px) {
      .product__img_first {
        height: 508px; } }
    @media screen and (max-width: 1024px) {
      .product__img_first {
        height: 510px; } }
    @media screen and (max-width: 600px) {
      .product__img_first {
        height: 220px; } }

.product__info {
  width: 50%; }
  @media screen and (max-width: 1440px) {
    .product__info {
      width: 57.5%; } }
  @media screen and (max-width: 1024px) {
    .product__info {
      margin-top: 40px;
      width: 100%; } }
  @media screen and (max-width: 600px) {
    .product__info {
      margin-top: 25px; } }

.product__price-info {
  margin-bottom: 40px; }
  @media screen and (max-width: 1024px) {
    .product__price-info {
      margin-bottom: 30px;
      justify-content: space-between; } }
  @media screen and (max-width: 600px) {
    .product__price-info {
      flex-flow: column;
      align-items: flex-start !important; } }

.product__price-text {
  min-width: 320px; }
  @media screen and (max-width: 600px) {
    .product__price-text {
      margin-bottom: 30px;
      min-width: 100%; } }

.product__price {
  font-size: 38px;
  font-weight: 500;
  line-height: 46px;
  letter-spacing: -0.04em; }
  @media screen and (max-width: 1440px) {
    .product__price {
      font-size: 44px;
      line-height: 30px; } }
  @media screen and (max-width: 1024px) {
    .product__price {
      font-size: 38px; } }
  @media screen and (max-width: 600px) {
    .product__price {
      font-size: 26px;
      line-height: 22px; } }

.product__intro {
  margin-bottom: 40px; }
  @media screen and (max-width: 600px) {
    .product__intro {
      margin-bottom: 30px; } }

.payment_item {
  width: 23%;
  margin-right: 2%;
  background-repeat: no-repeat;
  background-position: center;
  height: 70px;
  float: left;
  background-size: contain; }

.payment_item:first-child, .payment_item:nth-child(5) {
  background-position: center left; }

.news__wrapper {
  flex-wrap: wrap; }
  @media screen and (max-width: 1024px) {
    .news__wrapper {
      flex-flow: column; } }
  .news__wrapper::after {
    content: '';
    width: 29%; }
    @media screen and (max-width: 1440px) {
      .news__wrapper::after {
        width: 32%; } }
    @media screen and (max-width: 1024px) {
      .news__wrapper::after {
        content: none; } }

.news__item {
  width: 29%; }
  @media screen and (max-width: 1440px) {
    .news__item {
      width: 32%; } }
  @media screen and (max-width: 800px) {
    .news__item {
      margin-top: 20px;
      width: 49.5%; } }
  .news__item:nth-child(n+4) {
    margin-top: 90px; }
    @media screen and (max-width: 1440px) {
      .news__item:nth-child(n+4) {
        margin-top: 60px; } }
    @media screen and (max-width: 800px) {
      .news__item:nth-child(n+4) {
        margin-top: 20px; } }

.news__img {
  margin-bottom: 30px;
  width: 100%; }

.news__title {
  margin-bottom: 20px;
  display: block;
  font-size: 30px;
  font-weight: 400;
  line-height: 39px;
  color: #538E4A;
  text-decoration: underline;
  transition: all 0.5s; }
  @media screen and (max-width: 1440px) {
    .news__title {
      font-size: 28px;
      line-height: 38px; } }
  @media screen and (max-width: 1024px) {
    .news__title {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 24px; } }
  .news__title:hover {
    color: #5dab89;
    text-decoration: underline; }

.news__intro {
  margin-bottom: 20px;
  display: block;
  font-size: 24px;
  font-weight: 200;
  line-height: 33px;
  letter-spacing: -0.04em;
  color: #3f3f3f; }
  @media screen and (max-width: 1440px) {
    .news__intro {
      font-size: 22px; } }
  @media screen and (max-width: 1024px) {
    .news__intro {
      font-size: 18px;
      line-height: 23px; } }
  @media screen and (max-width: 600px) {
    .news__intro {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px; } }

.news__date,
.news .views {
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: -0.04em;
  color: #afafaf; }
  @media screen and (max-width: 1024px) {
    .news__date,
    .news .views {
      font-size: 14px;
      line-height: 22px; } }
  .news__date_before,
  .news .views_before {
    margin-right: 35px;
    padding-left: 28px;
    position: relative; }
    .news__date_before::before,
    .news .views_before::before {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      width: 14px;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../img/calendar.png); }

.blog .news__img {
  margin-bottom: 0;
  width: 26.5%;
  height: 312px;
  align-self: baseline;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  @media screen and (max-width: 1660px) {
    .blog .news__img {
      width: 21vw;
      height: 15vw; } }
  @media screen and (max-width: 1024px) {
    .blog .news__img {
      width: 30vw;
      height: 21vw; } }
  @media screen and (max-width: 600px) {
    .blog .news__img {
      margin-bottom: 15px;
      width: 98vw;
      height: 69vw; } }

.blog .news__info {
  width: 69.5%; }
  @media screen and (max-width: 1440px) {
    .blog .news__info {
      width: 74.5%; } }
  @media screen and (max-width: 1024px) {
    .blog .news__info {
      width: 65%; } }
  @media screen and (max-width: 600px) {
    .blog .news__info {
      width: 100%; } }

.blog .news__item {
  width: 100%; }
  @media screen and (max-width: 600px) {
    .blog .news__item {
      flex-direction: column; } }
  .blog .news__item:not(:first-child) {
    margin-top: 50px; }
    @media screen and (max-width: 1024px) {
      .blog .news__item:not(:first-child) {
        margin-top: 40px; } }
    @media screen and (max-width: 600px) {
      .blog .news__item:not(:first-child) {
        margin-top: 30px; } }

.blog .news__intro {
  margin-bottom: 0;
  margin-top: 20px; }
  @media screen and (max-width: 1440px) {
    .blog .news__intro {
      margin-top: 17px;
      -webkit-line-clamp: 4;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden; } }
  @media screen and (max-width: 1024px) {
    .blog .news__intro {
      -webkit-line-clamp: 3; } }
  @media screen and (max-width: 600px) {
    .blog .news__intro {
      margin-bottom: 0;
      -webkit-line-clamp: unset; } }

.article .news__header {
  margin-bottom: 50px;
  flex-wrap: wrap; }
  @media screen and (max-width: 1024px) {
    .article .news__header {
      margin-bottom: 30px; } }
  @media screen and (max-width: 600px) {
    .article .news__header {
      margin-bottom: 15px; } }

.article .news__img {
  margin-bottom: 0;
  width: 40.5%;
  height: 482px;
  align-self: baseline;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  @media screen and (max-width: 1660px) {
    .article .news__img {
      width: 35vw;
      height: 25vw; } }
  @media screen and (max-width: 1024px) {
    .article .news__img {
      margin-bottom: 40px;
      width: 98vw;
      height: 69vw;
      display: block; } }

.article .news__info {
  width: 54.5%; }
  @media screen and (max-width: 1440px) {
    .article .news__info {
      width: 57.5%; } }
  @media screen and (max-width: 1024px) {
    .article .news__info {
      width: 100%; } }

.views {
  padding-left: 35px;
  position: relative; }
  .views::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 22px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../img/eye.png); }

.popup__block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 544px;
  max-width: 90%;
  padding: 34px 40px 40px;
  box-sizing: border-box;
  text-align: center; }
  @media screen and (max-width: 600px) {
    .popup__block {
      padding: 34px 21px 40px; } }
  .popup__block_reviews {
    position: relative;
    margin: auto;
    padding: 0;
    top: 0;
    left: 0;
    transform: none; }
    .popup__block_reviews .popup__answer {
      margin-bottom: 0; }

.popup__title {
  margin-bottom: 20px;
  display: block;
  font-size: 30px;
  font-weight: 400;
  line-height: 47px;
  color: #63B4E9;
  text-align: center;
  text-transform: uppercase; }
  @media screen and (max-width: 600px) {
    .popup__title {
      font-size: 20px;
      line-height: 28px; } }

.popup__answer {
  margin: 0 auto 35px;
  display: block;
  max-width: 380px;
  font-size: 26px;
  font-weight: 300;
  line-height: 34px;
  letter-spacing: -0.04em; }
  @media screen and (max-width: 600px) {
    .popup__answer {
      margin: 0 auto 20px;
      max-width: 260px;
      font-size: 18px;
      line-height: 27px; } }
  .popup__answer_color {
    display: inline;
    color: #63B4E9; }

.popup__img {
  margin: 0 auto 30px;
  padding: 45px 43px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 0px 15px 20px rgba(46, 145, 121, 0.08); }
  @media screen and (max-width: 1024px) {
    .popup__img {
      width: 167px;
      height: 167px;
      padding: 38px 36px; } }
  @media screen and (max-width: 600px) {
    .popup__img {
      width: 108px;
      height: 108px;
      padding: 24px 23px; } }

.popup__svg {
  width: 114px;
  height: 110px;
  fill: #63B4E9; }
  @media screen and (max-width: 1024px) {
    .popup__svg {
      width: 95px;
      height: 91px; } }
  @media screen and (max-width: 600px) {
    .popup__svg {
      width: 62px;
      height: 60px; } }

.popup .captcha-item {
  margin-top: 10px; }

/* ************************************************* OLD styles **************************************************** */
.main_popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  opacity: 0;
  overflow: auto;
  transition: 0.5s; }
  .main_popup .popup_wrap {
    min-height: 700px;
    position: relative;
    width: 100%;
    height: 100%; }
    .main_popup .popup_wrap_service {
      min-height: 855px; }

.main_popup.active {
  opacity: 1; }

.main_popup .popup_close {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer; }

.main_popup .popup, .thanks_block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 544px;
  max-width: 90%;
  padding: 34px 40px 40px;
  box-sizing: border-box; }

.popup_close_x {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
  cursor: pointer; }

.popup_close_x:before, .popup_close_x:after {
  content: '';
  height: 17px;
  width: 2px;
  border-radius: 5px;
  transform: rotate(45deg);
  position: absolute;
  background-color: #000;
  left: 7px;
  top: -1px; }

.popup_close_x:before {
  transform: rotate(-45deg); }

.popup_title, .thanks_title {
  display: block;
  text-transform: uppercase;
  font-size: 26px;
  color: #63B4E9;
  text-align: center;
  margin-top: 0; }

.popup form {
  margin: 0; }

.popup input, .popup textarea {
  width: 100%;
  height: 46px;
  border: 1px solid #d0d0d0 !important;
  font-size: 16px;
  position: relative; }

.popup textarea {
  height: 170px;
  margin-bottom: 15px; }

.popup_feedback_product p {
  margin-bottom: 15px; }

.popup .placeholder {
  position: relative; }
  .popup .placeholder:not(:first-of-type) {
    margin-top: 25px; }
    @media screen and (max-width: 600px) {
      .popup .placeholder:not(:first-of-type) {
        margin-top: 20px; } }

.popup .placeholder span {
  color: #f84141;
  font-size: 16px;
  position: absolute;
  top: 12px;
  z-index: 6;
  right: 10px;
  display: none; }

.popup .placeholder input:required + span {
  display: block; }

.popup .placeholder input::placeholder,
.popup .placeholder textarea::placeholder {
  font-style: italic;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  line-height: 32px; }
  @media screen and (max-width: 600px) {
    .popup .placeholder input::placeholder,
    .popup .placeholder textarea::placeholder {
      font-size: 14px;
      line-height: 17px; } }

.popup .placeholder textarea:-ms-input-placeholder {
  font-style: italic;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  line-height: 32px; }
  @media screen and (max-width: 600px) {
    .popup .placeholder textarea:-ms-input-placeholder {
      font-size: 14px;
      line-height: 17px; } }

.popup .placeholder input:-ms-input-placeholder {
  font-style: italic;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  line-height: 32px; }
  @media screen and (max-width: 600px) {
    .popup .placeholder input:-ms-input-placeholder {
      font-size: 14px;
      line-height: 17px; } }

.jq-number__field {
  width: 42px;
  height: 42px;
  border-radius: 0;
  border: none;
  box-shadow: none; }

.jq-number {
  padding-right: 19px;
  margin: 10px 0 15px; }

.jq-number__field input {
  border-radius: 0;
  color: #565656;
  text-align: center;
  height: 42px;
  font-weight: 600; }

.jq-number__spin {
  width: 20px;
  height: 21px;
  border: 1px solid #d0d0d0;
  box-shadow: none;
  border-radius: 0;
  background: none; }

.jq-number__spin:after {
  width: 6px;
  height: 6px;
  border: none;
  border-right: 1px solid #c1c1c1;
  border-top: 1px solid #c1c1c1;
  transform: rotate(-45deg);
  top: 8px;
  left: 6px; }

.jq-number__spin.minus:after {
  border: none;
  border-right: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
  transform: rotate(45deg); }

@media (max-width: 450px) {
  .main_popup .popup {
    padding-left: 20px;
    padding-right: 20px; } }

.review {
  text-align: center;
  background-color: #fff;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
  padding: 35px 35px 80px 35px;
  position: relative; }
  @media screen and (max-width: 700px) {
    .review {
      padding: 25px 10px 55px; } }
  .review .svg_icon {
    fill: #eaeaea;
    position: absolute;
    bottom: 15px;
    left: 50%;
    width: 64px;
    height: 40px;
    margin-left: -32px; }
    @media screen and (max-width: 700px) {
      .review .svg_icon {
        height: 30px;
        bottom: 8px; } }
  .review_name {
    font-weight: 500;
    font-size: 20px;
    color: #565656; }
  .review_date {
    font-size: 14px;
    color: #538E4A;
    font-weight: 400;
    padding: 5px 0; }
  .review_text {
    font-size: 16px;
    color: #565656;
    font-weight: 300; }
    @media screen and (max-width: 700px) {
      .review_text {
        font-size: 14px; } }
    .review_text_hidden {
      display: none; }
  .review_read {
    font-size: 14px;
    color: #538E4A;
    border-bottom: 1px dashed;
    cursor: pointer;
    transition: all .25s linear;
    margin-top: 15px;
    display: inline-block;
    line-height: 1.25; }

.reviews.inner_page {
  flex-wrap: wrap; }
  .reviews.inner_page .review {
    width: 49%;
    margin-bottom: 40px; }
    @media screen and (max-width: 700px) {
      .reviews.inner_page .review {
        margin-bottom: 20px;
        width: 100%; } }

.social_links {
  display: none; }
  .social_links .svg_icon {
    width: 38px;
    height: 30px;
    fill: rgba(255, 255, 255, 0.6);
    display: block;
    transition: all 0.3s; }
    @media screen and (max-width: 1200px) {
      .social_links .svg_icon {
        width: 30px;
        height: 25px; } }
    .social_links .svg_icon:hover {
      fill: #fff; }
  .social_links .fb .svg_icon {
    height: 38px;
    width: 34px; }
    @media screen and (max-width: 1200px) {
      .social_links .fb .svg_icon {
        height: 30px;
        width: 28px; } }
  .social_links .vk .svg_icon {
    height: 34px; }
    @media screen and (max-width: 1200px) {
      .social_links .vk .svg_icon {
        height: 28px; } }
  .social_links .social_link {
    margin-right: 25px; }
    @media screen and (max-width: 1200px) {
      .social_links .social_link {
        margin-right: 15px; } }
    .social_links .social_link:last-child {
      margin-right: 0; }

.page_contacts .social_links {
  display: flex; }

.main_contacts {
  padding: 60px 0;
  position: relative;
  background-color: #63B4E9;
  margin: 0; }
  .main_contacts .wrap-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    flex-wrap: wrap; }
    @media screen and (max-width: 1024px) {
      .main_contacts .wrap-flex .captcha-item {
        margin-bottom: 10px; } }

.main_contacts h2, .contacts_title {
  color: #fff; }

.main_contacts .left_contacts .contacts_phone {
  margin-top: 0; }
  @media screen and (max-width: 600px) {
    .main_contacts .left_contacts .contacts_phone {
      margin-top: 6px; } }

.contacts_title {
  font-size: 40px;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: -0.04em; }
  @media screen and (max-width: 1440px) {
    .contacts_title {
      font-size: 30px;
      line-height: 56px; } }
  @media screen and (max-width: 1024px) {
    .contacts_title {
      font-size: 22px;
      line-height: 20px; } }
  @media screen and (max-width: 600px) {
    .contacts_title {
      font-size: 20px;
      line-height: 28px; } }

.left_contacts, .right_contacts {
  float: left;
  box-sizing: border-box; }

.left_contacts {
  width: 37%;
  padding-right: 8px;
  border-right: 1px solid rgba(255, 255, 255, 0.35); }
  .left_contacts .contacts_title {
    margin-bottom: 30px; }
    @media screen and (max-width: 600px) {
      .left_contacts .contacts_title {
        margin-bottom: 4px; } }
  .left_contacts .social_links {
    opacity: 50%; }
    @media screen and (max-width: 600px) {
      .left_contacts .social_links {
        width: 100%;
        padding-top: 20px; } }

.right_contacts {
  width: 63%; }

.footer_contacts_title {
  display: block;
  font-size: 18px;
  font-weight: 300;
  color: #B6E2FF; }

.footer_contacts_block, .footer_contacts_block a {
  color: #ffffff;
  text-decoration: none;
  font-size: 22px;
  font-weight: 500; }

.footer_contacts_block {
  margin-bottom: 20px;
  padding-left: 50px;
  position: relative;
  width: 80%; }

.footer_contacts_block:before {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  left: 0;
  top: 5px;
  background-size: contain; }

.contacts_phone {
  margin-top: 35px; }

.contacts_phone:before {
  background-image: url(../img/svg/f001_phone.svg); }

.contacts_mail:before {
  background-image: url(../img/svg/f002_envelope.svg); }

.contacts_address:before {
  background-image: url(../img/svg/f003_location.svg); }

.contacts_time:before {
  background-image: url(../img/svg/f004_clock.svg); }

.right_contacts_desc span {
  font-weight: 600; }

.right_contacts_desc {
  font-size: 30px;
  font-weight: 300;
  color: #fff;
  margin: 20px 0;
  margin-bottom: 70px; }

.right_contacts {
  padding-left: 100px; }
  .right_contacts .checkbox_block {
    margin-bottom: 0; }
  .right_contacts .checkbox_text {
    color: #b6e2ff;
    font-size: 20px; }
    .right_contacts .checkbox_text:before {
      color: #fff;
      border-color: #fff;
      position: absolute;
      top: 5px;
      left: 4px; }
    .right_contacts .checkbox_text a {
      color: #fff; }

.right_contacts_form {
  flex-wrap: wrap; }

.right_contacts_form .input_block input {
  height: 40px;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.35);
  color: #fff;
  font-weight: 300; }

.right_contacts_form .input_block input::placeholder {
  font-size: 20px;
  color: #fff; }

.right_contacts_form .input_block {
  width: 47%;
  margin-right: 6%;
  margin-bottom: 60px; }

.right_contacts_form .input_block:nth-child(2n) {
  margin-right: 0; }

.right_contacts_form .input_block:nth-child(3) {
  margin-bottom: 70px; }
  @media screen and (max-width: 768px) {
    .right_contacts_form .input_block:nth-child(3) {
      margin-bottom: 20px; } }

.right_contacts_form .input_block:nth-child(4) {
  margin-bottom: 70px; }
  @media screen and (max-width: 768px) {
    .right_contacts_form .input_block:nth-child(4) {
      margin-bottom: 20px; } }

.right_contacts_form .checkbox_block {
  width: 100%;
  order: 0;
  margin-top: -40px; }
  @media screen and (max-width: 1200px) {
    .right_contacts_form .checkbox_block {
      width: 100%;
      margin-bottom: 30px; } }
  @media screen and (max-width: 768px) {
    .right_contacts_form .checkbox_block {
      margin-top: 10px;
      margin-bottom: 10px; } }
  @media screen and (max-width: 600px) {
    .right_contacts_form .checkbox_block {
      margin-bottom: 25px; } }

@media screen and (max-width: 1440px) {
  .right_contacts_form .button {
    width: 47%; } }

@media screen and (max-width: 1200px) {
  .right_contacts_form .button {
    width: 293px; } }

@media screen and (max-width: 600px) {
  .right_contacts_form .button {
    margin-bottom: 40px; } }

@media screen and (max-width: 450px) {
  .right_contacts_form .button {
    width: 100%; } }

.checkbox_text {
  position: relative;
  line-height: 30px;
  vertical-align: middle;
  z-index: 5;
  font-weight: 300;
  color: #565656;
  margin: 0;
  padding-left: 40px; }
  @media screen and (max-width: 768px) {
    .checkbox_text {
      line-height: 1.6; } }
  .checkbox_text:before {
    content: " ";
    color: #565656;
    display: inline-block;
    margin-right: 14px;
    position: absolute;
    text-align: center;
    text-indent: 0px;
    width: 22px;
    height: 22px;
    background: inherit;
    border: 1px solid #565656;
    border-image: initial;
    vertical-align: middle;
    margin-bottom: 2px;
    left: 0;
    top: 3px; }
  .checkbox_text a {
    color: #565656;
    text-decoration: none;
    font-weight: 500;
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 1px; }

.conf_checkbox:checked + .checkbox_text:after {
  content: "";
  display: block;
  width: 7px;
  height: 13px;
  border-bottom: 1px solid #565656;
  border-right: 1px solid #565656;
  position: absolute;
  top: 5px;
  left: 9px;
  transform: rotate(45deg); }
  @media screen and (max-width: 768px) {
    .conf_checkbox:checked + .checkbox_text:after {
      top: 5px;
      left: 6px;
      width: 6px;
      height: 10px; } }

.right_contacts .conf_checkbox:checked + .checkbox_text:after {
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  top: 7px;
  left: 11px; }
  @media screen and (max-width: 768px) {
    .right_contacts .conf_checkbox:checked + .checkbox_text:after {
      top: 6px;
      left: 9px;
      width: 6px;
      height: 10px; } }

.conf_checkbox:disabled + .checkbox_text:before {
  background: inherit;
  color: #aaa; }

.main_contacts:after {
  bottom: 0;
  right: 0;
  width: 21%;
  height: 30%;
  top: auto;
  left: auto; }

.checkbox_block {
  position: relative;
  text-align: left; }

input.conf_checkbox {
  position: absolute;
  top: 15px;
  left: 5px;
  width: 2px;
  height: 2px;
  margin: 0;
  opacity: 0; }

.contacts_page h1 {
  margin-bottom: 60px; }

.maps_block {
  margin-top: 50px; }

.get_to_us {
  margin-top: 30px;
  margin-bottom: 40px; }

.get_to_us span {
  text-decoration: underline;
  cursor: pointer;
  line-height: 40px;
  display: inline-block;
  padding: 0 25px; }

.get_to_us .get_to_auto {
  margin-right: 20px; }

.get_to_us span.active {
  background-color: #3883e8;
  color: #fff;
  -webkit-box-shadow: 1px 4px 18px rgba(109, 109, 109, 0.17);
  -moz-box-shadow: 1px 4px 18px rgba(109, 109, 109, 0.17);
  box-shadow: 1px 4px 18px rgba(109, 109, 109, 0.17);
  text-decoration: none; }

.get_to_block {
  margin-top: 40px;
  position: relative;
  display: none;
  width: 70%;
  display: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.get_to_block.active {
  display: block; }

.get_to_block li {
  position: relative; }

.contacts_page ul.get_to_block li:before {
  content: '';
  position: absolute;
  background-color: transparent;
  background-image: url(../img/yes.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  top: 11px;
  left: 0; }

.contacts_page .page__wrapper_padding {
  padding-bottom: 0; }

.yandex_maps {
  height: 620px; }
  @media screen and (max-width: 1024px) {
    .yandex_maps {
      height: 400px; } }
  @media screen and (max-width: 600px) {
    .yandex_maps {
      height: 302px; } }

.ymBlock {
  width: 100% !important; }

.ymaps-2-1-72-image {
  width: 40px !important;
  height: 66px !important;
  background-size: 40px 66px !important;
  top: -60px !important; }

.content_contact {
  margin: 50px 0 40px 0; }

.content_contact h3 {
  margin-bottom: 30px; }

/* media */
@media (max-width: 1460px) {
  .right_contacts_form .checkbox_text {
    display: block;
    width: 100%; } }

@media (max-width: 1300px) {
  .right_contacts_desc {
    font-size: 26px; }
  .contacts_title {
    font-size: 36px; } }

@media (max-width: 1000px) {
  .contacts_title {
    font-size: 24px; }
  .right_contacts_desc {
    font-size: 20px; } }

@media (max-width: 768px) {
  .get_to_block {
    width: 100%; }
  .right_contacts {
    padding-left: 30px; }
  .right_contacts_form .input_block {
    width: 330px;
    max-width: 90%;
    margin: 0 0 20px 0; }
  .right_contacts_form .input_block input {
    margin-bottom: 0; }
  .right_contacts_desc {
    font-size: 14px;
    margin-bottom: 20px; }
  .footer_contacts_title {
    font-size: 14px; }
  .footer_contacts_block, .footer_contacts_block a {
    font-size: 18px; }
  .contacts_social a {
    background-size: contain;
    width: 30px;
    height: 30px; }
  .right_contacts_form .checkbox_text {
    font-size: 12px;
    margin: 0 0 8px 0; }
  label.checkbox_text:before {
    width: 16px;
    height: 16px;
    font: 14px/16px Arial;
    margin-right: 10px; }
  .main_contacts:before {
    width: 41%;
    height: 37%; }
  .main_contacts:after {
    width: 50%;
    height: 40%; }
  .get_to_block:before {
    top: 3px; } }

@media (max-width: 600px) {
  .contacts_title {
    font-size: 20px; }
  .left_contacts, .right_contacts {
    float: none;
    width: 100%; }
  .main_contacts {
    display: table;
    padding: 20px 0; }
  .left_contacts {
    display: table-footer-group;
    line-height: 1.5; }
  .right_contacts {
    display: table-header-group;
    line-height: 1.5; }
  .right_contacts_form .input_block {
    margin-bottom: 15px; }
  .right_contacts_desc {
    margin-top: 10px;
    font-size: 12px;
    line-height: 14px; }
  .right_contacts_form .input_block {
    width: 100%;
    max-width: 100%; }
  .right_contacts_form .input_block input {
    font-size: 12px;
    height: 20px; }
    .right_contacts_form .input_block input::placeholder {
      font-size: 12px; }
  .footer_contacts_block {
    width: 47%;
    float: left;
    box-sizing: border-box;
    margin: 5px 6% 0 0;
    padding-left: 34px; }
  .footer_contacts_block:nth-child(2n+1) {
    margin-right: 0; }
  .footer_contacts_title, .footer_contacts_block, .footer_contacts_block a {
    font-size: 12px; }
  .footer_contacts_block:before {
    top: 2px;
    width: 22px;
    height: 20px;
    background-size: contain; }
  .left_contacts .contacts_social {
    clear: both;
    padding-top: 18px; }
  .contacts_social a {
    width: 22px;
    height: 22px;
    margin-right: 10px; }
  .contacts_address:before {
    top: 0;
    height: 31px; }
  .contacts_phone:before {
    top: 3px; } }

.fed_docs__wrapper {
  flex-wrap: wrap; }

.fed_docs__unit {
  width: 100%;
  margin-bottom: 12px; }
  @media screen and (max-width: 600px) {
    .fed_docs__unit {
      align-items: flex-start !important; } }

.fed_docs__icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 16px;
  transform: translateY(-2px);
  align-self: top; }
  .fed_docs__icon img {
    width: 100%; }
  @media screen and (max-width: 600px) {
    .fed_docs__icon {
      transform: translateY(10px);
      height: 11px; } }

@media screen and (max-width: 768px) {
  .fed_docs__link {
    font-size: 14px; } }

.fed_docs__link:hover {
  text-decoration: underline;
  text-underline-offset: 2px; }

.prices .fed_docs__unit {
  margin-bottom: 50px; }

.prices .fed_docs__icon {
  width: 50px;
  height: 50px;
  margin-right: 30px; }
  @media screen and (max-width: 768px) {
    .prices .fed_docs__icon {
      width: 26px;
      height: 26px; } }

.prices .fed_docs__link {
  font-size: 26px; }
  @media screen and (max-width: 768px) {
    .prices .fed_docs__link {
      font-size: 18px; } }
  .prices .fed_docs__link:hover {
    text-underline-offset: 4px; }

@media screen and (max-width: 600px) {
  .desktop_br {
    display: none; } }

@media screen and (max-width: 600px) {
  .page_gallery .subtitle {
    margin-bottom: 20px; } }

.slider {
  margin: auto;
  max-width: 1660px;
  color: #001A11; }
  .slider__unit {
    padding: 70px 50px;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
    .slider__unit .container {
      height: 100%; }
    @media screen and (max-width: 1440px) {
      .slider__unit {
        padding: 75px 0; } }
    @media screen and (max-width: 1024px) {
      .slider__unit {
        padding: 45px 11px;
        min-height: 345px; } }
    @media screen and (max-width: 700px) {
      .slider__unit {
        height: 374px; } }
    @media screen and (max-width: 768px) {
      .slider__unit {
        background-position: 10% center; } }
    @media screen and (max-width: 600px) {
      .slider__unit {
        background-position: 15% center; } }
    @media screen and (max-width: 450px) {
      .slider__unit {
        padding: 20px 0;
        min-height: 240px;
        background-position: 25% center; } }
  .slider__content_wrapper {
    width: 560px;
    height: 100%;
    display: flex;
    flex-direction: column; }
    @media screen and (max-width: 767px) {
      .slider__content_wrapper {
        width: 100%; } }
  .slider__title {
    margin-bottom: 35px;
    font-size: 54px;
    font-weight: 600;
    line-height: 1;
    width: 80%; }
    @media screen and (max-width: 1024px) {
      .slider__title {
        margin-bottom: 30px;
        font-size: 36px;
        line-height: 42px; } }
    @media screen and (max-width: 450px) {
      .slider__title {
        margin-bottom: 25px;
        padding: 0 10px;
        font-size: 28px;
        line-height: 34px; } }
  .slider__text {
    margin-bottom: 20px;
    font-size: 41px;
    font-weight: 400;
    line-height: 58px; }
    .slider__text ul {
      display: flex;
      flex-wrap: wrap; }
      .slider__text ul li {
        padding-left: 34px;
        font-size: 24px;
        line-height: 1.3;
        position: relative; }
        @media screen and (max-width: 700px) {
          .slider__text ul li {
            font-size: 18px; } }
        .slider__text ul li:before {
          content: '';
          display: block;
          width: 14px;
          height: 14px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background-image: url(../img/svg/check.svg);
          background-position: center; }
      .slider__text ul.two_col li {
        width: 50%;
        display: flex;
        align-items: center;
        margin-bottom: 10px; }
      .slider__text ul.custom_columns li:nth-child(odd) {
        width: 47%; }
        @media screen and (max-width: 700px) {
          .slider__text ul.custom_columns li:nth-child(odd) {
            width: 100%; } }
      .slider__text ul.custom_columns li:nth-child(even) {
        width: 53%; }
        @media screen and (max-width: 700px) {
          .slider__text ul.custom_columns li:nth-child(even) {
            width: 100%; } }
    @media screen and (max-width: 1024px) {
      .slider__text {
        margin-bottom: 30px;
        font-size: 26px;
        line-height: 36px; } }
    @media screen and (max-width: 450px) {
      .slider__text {
        margin-bottom: 20px;
        padding: 0 10px;
        font-size: 20px;
        line-height: 30px; } }
  .slider__button {
    box-shadow: none;
    border-radius: 5px;
    margin-top: auto; }

.about {
  padding: 50px 0 100px; }
  .about .services__link {
    position: static;
    text-underline-offset: 2px;
    text-decoration-thickness: 1px; }
  @media screen and (max-width: 1440px) {
    .about {
      padding: 25px 0 75px; } }
  @media screen and (max-width: 1024px) {
    .about {
      padding: 40px; } }
  @media screen and (max-width: 600px) {
    .about {
      padding: 50px 0 40px; } }
  .about__title {
    margin-bottom: 50px;
    font-size: 68px;
    font-weight: 600;
    line-height: 82px;
    letter-spacing: -0.04em;
    color: #001A11; }
    @media screen and (max-width: 1400px) {
      .about__title {
        margin-bottom: 30px;
        font-size: 56px;
        line-height: 68px; } }
    @media screen and (max-width: 1024px) {
      .about__title {
        font-size: 38px;
        line-height: 36px; } }
    @media screen and (max-width: 600px) {
      .about__title {
        margin-bottom: 20px;
        font-size: 30px;
        line-height: 34px; } }
    @media screen and (max-width: 450px) {
      .about__title {
        font-size: 26px;
        line-height: 32px; } }
  .about__content p {
    margin-bottom: 20px; }
    .about__content p:not(:first-child) {
      margin-top: 20px; }
      @media screen and (max-width: 1024px) {
        .about__content p:not(:first-child) {
          margin-top: 15px; } }
  .about__img {
    margin-bottom: 20px;
    margin-left: 60px;
    width: 49%;
    height: 635px;
    float: right; }
    @media screen and (max-width: 1400px) {
      .about__img {
        margin-left: 35px;
        height: auto;
        object-fit: contain; } }
    @media screen and (max-width: 1024px) {
      .about__img {
        display: none; } }
  .about__button {
    margin-top: 50px; }
    @media screen and (max-width: 1024px) {
      .about__button {
        margin-top: 40px; } }
    @media screen and (max-width: 768px) {
      .about__button {
        margin-top: 30px; } }
    @media screen and (max-width: 450px) {
      .about__button {
        margin-top: 40px; } }

.news-preview {
  padding: 70px 0 85px;
  background-color: #EBF7F9; }
  @media screen and (max-width: 1440px) {
    .news-preview {
      padding: 100px 0 70px; } }
  @media screen and (max-width: 1024px) {
    .news-preview {
      padding: 30px 0; } }
  @media screen and (max-width: 600px) {
    .news-preview {
      padding: 35px 0; } }
  @media screen and (max-width: 767px) {
    .news-preview__wrapper {
      flex-wrap: wrap; } }
  .news-preview__img {
    margin-right: 50px;
    margin-bottom: 10px;
    width: 47.5%;
    flex-shrink: 0;
    align-self: baseline; }
    @media screen and (max-width: 1440px) {
      .news-preview__img {
        margin-right: 58px;
        width: 37.5%; } }
    @media screen and (max-width: 768px) {
      .news-preview__img {
        display: none; } }
  .news-preview__subtitle {
    display: inline-block;
    max-width: 500px; }
  .news-preview__unit {
    margin-top: 20px;
    width: 30%; }
    @media screen and (max-width: 767px) {
      .news-preview__unit {
        width: 100%; } }
  .news-preview__link {
    display: block;
    font-size: 30px;
    font-weight: 600;
    line-height: 39px;
    color: #538E4A;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    transition: all 0.5s;
    margin-bottom: 20px; }
    @media screen and (max-width: 1440px) {
      .news-preview__link {
        font-size: 28px;
        line-height: 38px; } }
    @media screen and (max-width: 1024px) {
      .news-preview__link {
        font-size: 22px;
        line-height: 30px; } }
    @media screen and (max-width: 768px) {
      .news-preview__link {
        font-size: 16px;
        line-height: 24px; } }
    .news-preview__link:hover {
      color: #5dab89;
      text-decoration: underline;
      text-decoration-thickness: 1px; }
  .news-preview__text {
    display: block;
    margin: 5px 0 10px;
    letter-spacing: -0.04em;
    color: #3f3f3f; }
    @media screen and (max-width: 1024px) {
      .news-preview__text {
        line-height: 28px; } }
    @media screen and (max-width: 768px) {
      .news-preview__text {
        line-height: 23px;
        letter-spacing: normal; } }
    @media screen and (max-width: 450px) {
      .news-preview__text {
        line-height: 20px; } }
  .news-preview__date {
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: #afafaf; }
    @media screen and (max-width: 1024px) {
      .news-preview__date {
        font-size: 16px;
        line-height: 26px; } }
    @media screen and (max-width: 768px) {
      .news-preview__date {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: normal; } }
