.catalog {
  position: relative;
  padding: 70px 0 80px;
  background-color: $background_color;

  @include media(1440px) {
    padding: 50px 0;
  }

  @include media(1024px) {
    padding: 30px 0 40px;
  }

  @include media(600px) {
    padding: 30px 0 25px;
  }

  &__header {
    margin-bottom: 50px;

    @include media(1440px) {
      margin-bottom: 30px;
    }

    @include media(600px) {
      margin-bottom: 20px;
    }
  }

  &__wrapper {
    flex-wrap: wrap;
  }

  &__unit {
    position: relative;
    width: 31.5%;
    min-height: 533px;
    padding: 20px 20px 45px;
    background-color: #fff;
    z-index: 1;

    @include media(1440px) {
      min-height: 615px;
      padding: 20px 15px 65px;
    }

    @include media(1024px) {
      min-height: 325px;
      padding: 10px 10px 30px;
    }

    @include media(600px) {
      width: 100%;
      min-height: 240px;
      padding-bottom: 25px;
    }

    &:nth-child(n+4) {
      margin-top: 35px;

      @include media(1440px) {
        margin-top: 50px;
      }

      @include media(1024px) {
        margin-top: 30px;
      }
    }
  }

  &__img {
    margin-bottom: 30px;
    width: 100%;
    height: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include media(1440px) {
      height: 264px;
    }

    @include media(1024px) {
      margin-bottom: 20px;
      height: 126px;
    }

    @include media(600px) {
      margin-bottom: 10px;
      height: 77px;
    }
  }

  &__name {
    margin-bottom: 13px;
    font-size: 30px;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: -0.04em;
    color: $text_main_color;
    text-align: center;

    @include media(1440px) {
      margin-bottom: 20px;
      font-size: 26px;
      line-height: 22px;
    }

    @include media(1024px) {
      font-size: 20px;
    }

    @include media(768px) {
      margin-bottom: 10px;
      font-size: 14px;
    }

    &:hover {
      color: $main_color;
    }
  }

  &__price {
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: $text_main_color;

    @include media(1440px) {
      margin-bottom: 15px;
      font-size: 26px;
    }

    @include media(1024px) {
      font-size: 20px;
    }

    @include media(768px) {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 26px;
    }

    @include media(450px) {
      line-height: 22px;
    }
  }

  &__cost {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;

    @include media(1440px) {
      font-size: 26px;
    }

    @include media(1024px) {
      font-size: 20px;
      line-height: 32px;
    }

    @include media(768px) {
      font-size: 14px;
      line-height: 26px;
    }

    @include media(450px) {
      line-height: 22px;
    }
  }

  &__exist {
    margin-bottom: 110px;
    font-size: 24px;
    font-weight: 300;
    line-height: 50px;
    letter-spacing: -0.04em;
    color: $button_color_first;

    @include media(1440px) {
      margin-bottom: 100px;
      font-size: 26px;
      line-height: 44px;
    }

    @include media(1024px) {
      margin-bottom: 70px;
      font-size: 20px;
      line-height: 34px;
    }

    @include media(768px) {
      font-size: 14px;
      line-height: 26px;
    }

    @include media(600px) {
      margin-bottom: 50px;
    }

    @include media(450px) {
      font-size: 12px;
      line-height: 22px;
    }

    &_no {
      color: #c3c3c3;
    }
  }

  &__button {
    @include media(600px) {
      display: none;
    }

    &_order {
      position: absolute;
      bottom: 45px;
      left: 10%;
      width: 80%;
      height: 68px;
      justify-content: center;
      font-size: 20px;
      line-height: 24px;
      border-radius: 5px;
      box-shadow: none;

      @include media(1440px) {
        bottom: 65px;
        max-width: 340px;
        height: 72px;
      }

      @include media(1200px) {
        width: 86%;
        font-size: 20px;
        line-height: 24px;
        left: inherit;
      }

      @include media(1024px) {
        bottom: 30px;
        width: 215px;
        height: 50px;
        font-size: 14px;
        line-height: 17px;
      }

      @include media(768px) {
        width: 90%;
      }

      @include media(600px) {
        bottom: 28px;
        width: 120px;
        height: 36px;
        font-size: 12px;
        line-height: 15px;
      }
    }

    &_mobile {
      display: none;

      @include media(600px) {
        display: flex;
        margin: 30px auto 0;
        left: calc((100% - 120px)/2);
      }
    }
  }
}

@include media(600px) {
  .page .catalog__unit {
    width: 46%;

    &:nth-child(n+3) {
      margin-top: 20px;
    }
  }
}