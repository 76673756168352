.gallery {
  padding: 120px 0;

  @include media(1440px) {
    padding: 50px 0 120px;
  }

  @include media(1024px) {
    padding: 50px 0 55px;
  }

  @include media(600px) {
    padding: 40px 0 30px;
  }

  &__header {
    margin-bottom: 45px;

    @include media(1440px) {
      margin-bottom: 50px;
    }

    @include media(1028px) {
      margin-bottom: 30px;
    }

    @include media(600px) {
      margin-bottom: 20px;
    }
  }

  &__button {
    @include media(600px) {
      display: none;
    }
  }

  &__item {
    display: block;
    position: relative;
    overflow: hidden;
    transition: 0.5s;
    cursor: pointer;
    &.docs_item {
        margin-top: 0!important;
        .gallery__name {
            height: 120px;
            padding-right: 20px;
        }
        &:hover {
          & .gallery__name {
            transform: translateY(-120px);
          }
        }
    }
    &:last-child {
      margin-top: 20px;
    }

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      & .gallery__hover {
        opacity: 1;
      }
  
      & .gallery__name {
        transform: translateY(-60px);
      }
    }
  }

  &__img {
    width: 100%;
  }

  &__hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0,0,0,.24);
    transition: 0.3s;
  }

  &__loupe {
    position: absolute;
    right: 10px;
    top: 15px;
    display: block;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: rgba(255,255,255,.32);
    background-image: url(../img/loupe.png);
    background-position: center;
    background-repeat: no-repeat;
  }

  &__name {
    position: absolute;
    top: 100%;
    left: 0;
    padding: 15px 0 15px 24px;
    width: 100%;
    font-weight: 200;
    font-style: italic;
    letter-spacing: normal;
    color: #fff;
    background-color: rgba(0,0,0,.37);
    box-sizing: border-box;
    transition: 0.6s;
  }

  & .owl-dots {
    margin-top: 60px;

    @include media(1024px) {
      margin-top: 40px;
    }

    @include media(600px) {
      margin-top: 30px;
    }
  }
}

.page_gallery .gallery {
  &__wrapper {
    flex-wrap: wrap;

    &::after {
      content: '';
      width: 24%;

      @include media(1024px) {
        width: 32%;
      }
    }

    &::before {
      content: '';
      width: 24%;
      order: 1;

      @include media(1024px) {
        width: 0%;
      }
    }
  }

  &__item {
    width: 24%;
    margin-bottom: 3%;

    @include media(1024px) {
      width: 32%;
    }

    @include media(600px) {
      width: 47%;
    }

    &:hover {
      transform: scale(1.1);
      z-index: 2;
    }

    &:nth-child(n+5) {
      margin-top: 20px;
    }

    @include media(1024px) {
      &:nth-child(n+4) {
        margin-top: 20px;
      }
    }

    @include media(600px) {
      &:nth-child(n+3) {
        margin-top: 20px;
      }
    }
  }
}

.service .gallery {
  padding: 0;
}