.about {
  padding: 50px 0 100px;
  .services__link {
    position: static;
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
  }
  @include media(1440px) {
    padding: 25px 0 75px;
  }

  @include media(1024px) {
    padding: 40px;
  }

  @include media(600px) {
    padding: 50px 0 40px;
  }

  &__title {
    margin-bottom: 50px;
    font-size: 68px;
    font-weight: 600;
    line-height: 82px;
    letter-spacing: -0.04em;
    color: #001A11;

    @include media(1400px) {
      margin-bottom: 30px;
      font-size: 56px;
      line-height: 68px;
    }

    @include media(1024px) {
      font-size: 38px;
      line-height: 36px;
    }

    @include media(600px) {
      margin-bottom: 20px;
      font-size: 30px;
      line-height: 34px;
    }

    @include media(450px) {
      font-size: 26px;
      line-height: 32px;
    }
  }

  &__content {
    p {
      margin-bottom: 20px;
      &:not(:first-child) {
        margin-top: 20px;
        @include media(1024px) {
          margin-top: 15px;
        }
      }
    }
  }

  &__img {
    margin-bottom: 20px;
    margin-left: 60px;
    width: 49%;
    height: 635px;
    float: right;

    @include media(1400px) {
      margin-left: 35px;
      height: auto;
      object-fit: contain;
    }

    @include media(1024px) {
      display: none;
    }
  }
  
  &__button {
    margin-top: 50px;

    @include media(1024px) {
      margin-top: 40px;
    }

    @include media(768px) {
      margin-top: 30px;
    }

    @include media(450px) {
      margin-top: 40px;
    }
  }
}