.navigation {
    background-color: $main_color;

    &__wrapper {
        height: 50px;
        
        .header__vision {
            display: none;
            @include media(700px) {
                display: flex;
            }
        }

        @include media(1024px) {
            height: 60px;
        }

        @include media(450px) {
            height: 52px;
        }
    }

    &__menu {
        flex-grow: 1;

        @include media(1024px) {
            display: none;
        }
    }

    &__list {
        height: 100%;
        display: flex;
        align-items: center;

        &.mm-listview {
            display: block;
        }
    }

    &__sublist {
        position: absolute;
        left: -9999px;
        width: 270px;
        padding: 16px 22px 22px;
        background-color: $main_color;
        opacity: 0;
        transition: opacity 0.5s;
        z-index: 5;

        &.mm-listview {
            position: relative;
            left: 0;
            width: 100%;
            padding: 0;
            opacity: 1;
        }
    }

    &__item {

        &.first {
            margin-left: 0;
        }

        &.active > .navigation__link {}

        &_lvl_1 {
            margin-left: 4.5%;
            position: relative;
            &:hover .navigation__sublist {
                left: 0;
                opacity: 1;
            }
            &.mm-listitem {
                margin-left: 0;
            }
            @include media(1660px){
                margin-left: 2.5%;
            }
            @include media(1440px) {
                margin-left: 2.2%;
            }

            @include media(1200px) {
                margin-left: 2%;
            }
        }

        &_lvl_2 {
            margin-bottom: 14px;

            &.last {
                margin-bottom: 0;
            }

            &.mm-listitem {
                margin-bottom: 0;
            }
        }
    }
    
    &__link {
        font-size: 18px;
        line-height: 24px;
        color: #fff;
        transition: color 0.5s;

        @include media(1440px) {
            font-size: 18px;
            line-height: 22px;
        }

        @include media(1200px) {
            font-size: 16px;
        }

        &:hover {
            color: $button_color_first;
        }
    }

    &__m-logo {
        display: none;
        width: 178px;
        height: 41px;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
        background-image: url(../img/svg/logoNew.svg);

        @include media(1024px) {
            display: block;
        }

        @include media(700px) {
            width: 150px;
            height: 35px;
        }

        @include media(450px) {
            width: 142px;
            height: 34px;
        }
    }

    &__m-phone {
        display: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #fff;

        @include media(1024px) {
            display: block;
        }
        
        @include media(700px) {
            width: 35px;
            height: 35px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            background-image: url(../img/svg/phone_footer.svg);
            text-indent: -9999px;
        }

        @include media(600px) {
            margin-right: 20px;
            margin-left: auto;
        }

        @include media(450px) {
            width: 26px;
            height: 26px;
            display: none;
        }
    }

    &__button {
        width: 220px;
        height: 100%;
        padding: 0;
        justify-content: center;
        box-shadow: none;
        font-size: 16px;
        line-height: 19px;
        text-decoration: underline;
        text-underline-offset: 2px;
        border-radius: 0;

        @include media(1440px) {
            width: 188px;
        }

        @include media(1024px) {
            width: 194px;
            height: 40px;
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
        }

        @include media(600px) {
            display: none;
        }
    }

    &__hamburger {
        display: none;
        width: 50px;
        height: 35px;
        justify-content: center;
        align-items: center;
        position: relative;

        @include media(1024px) {
            display: flex;
        }

        @include media(450px) {
            width: 36px;
            height: 25px;
        }

        &-add {
            width: 100%;
            height: 5px;
            background-color: #fff;
            border-radius: 3px;
            transition: background-color 0.3s;

            @include media(450px) {
                height: 3px;
            }

            &:before,
            &:after {
                content: '';
                width: 100%;
                height: 5px;
                position: absolute;
                background-color: #fff;
                border-radius: 3px;
                left: 0;
                transition: all 0.5s;

                @include media(450px) {
                    height: 3px;
                }
            }

            &:before {
                top: 0;
            }

            &:after {
                bottom: 0;
            }
        }

        &_active {
            .navigation__hamburger-add {
                background-color: transparent;

                &:before {
                    transform: rotate(45deg);
                    top: 14px;
                }

                &:after {
                    transform: rotate(-45deg);
                    bottom: 15px;
                }

                @include media(450px) {
                    &:before {
                        top: 11px;
                    }

                    &::after {
                        bottom: 11px;
                    }
                }
            }
        }
    }
}

.header {
    &__company-info {
        height: 126px;
        align-items: center;
        
        @include media(1660px) {
            flex-wrap: wrap;
            height: auto;
            padding: 30px 0;
        }

        @include media(1024px) {
            padding: 20px 0;
            align-items: center;
        }

        @include media(700px) {
            display: none;
        }
        .one_block {
            /*
            width: 49%;
            */
            display: flex;
            justify-content: space-between;
            /*
            @include media(1660px) {
                width: 100%;
                &:first-child {
                    margin-bottom: 16px;
                }
            }
            */
        }
    }

    &__logo {
        width: 230px;
        height: 66px;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
        background-image: url(../img/svg/logoNew.svg);
        @include media(1660px) {
            margin-right: 1%;
        }
        @include media(1440px) {
            width: 87px;
            height: 45px;
            background-size: cover;
            flex-shrink: 0;
        }

        @include media(1024px) {
            display: none;
        }
    }

    &__infoblock {
        flex-shrink: 0;

        &_address {
            max-width: 286px;

            @include media(1024px) {
                max-width: 240px;
            }
            @include media(900px) {
                width: 130px;
            }
            svg {
                @include media(1440px) {
                    width: 20px!important;
                    height: 18px!important;
                }
            }
        }
        
        &_worktime {
            .worktime_break {
                @include media(900px) {
                    display: block;
                }
            }
        }
    }

    &__svg {
        margin-top: 4px;
        margin-right: 16px;
        width: 20px;
        height: 20px;
        fill: transparent;
        stroke: $main_color;
        flex-shrink: 0;
        &:last-of-type {
            width: 31px;
            @include media(1440px) {
                width: 20px;
            }
        }
        @include media(1660px) {
            margin-right: 6px;
        }
        @include media(1440px) {
            width: 16px;
            height: 16px;
        }
        @include media(1024px) {
            margin-top: 0;
            margin-right: 4px;
        }
    }

    &__text {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        color: $text_second_color;
        
        @include media(1440px) {
            font-size: 14px;
            line-height: 20px;
        }

        &_bold {
            font-weight: 600;
        }
        b {
            font-weight: 600;
        }
    }

    &__social {
        fill: #bdbdbd;
    }

    &__phone {
        font-size: 22px;
        font-weight: 700;
        line-height: 34px;
        color: #3d3d3d;

        &:hover {
            color: #3d3d3d;
        }

        @include media(1440px) {
            font-size: 16px;
        }

    }

    &__link {
        font-size: 16px;
        font-weight: 300;
        line-height: 19px;
        color: $button_color_first;
        border-bottom: 1px solid $button_color_first;

        &:hover {
            color: $main_color;
            border-bottom: 1px solid $main_color;
        }
        @include media(1440px) {
            font-size: 14px;
            line-height: 17px;
        }
    }
    &__search {
        width: 300px;
        display: flex;
        align-items: center;
        @include media(1660px) {
            max-width: 160px;
        }
        .input_wrapper {
            position: relative;
            width: 100%;
            .headerSearchButton {
                width: 24px;
                height: 24px;
                display: block;
                padding: 0;
                background-image: url(../img/svg/search.svg);
                background-size: auto;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                left: 19px;
                top: 50%;
                transform: translateY(-50%);
                border: none;
                @include media(1660px) {
                    left: 8px;
                }
                
            }
        }
        input {
            padding: 13px 13px 13px 54px;
            font-size: 16px;
            width: 100%;
            height: 50px;
            @include media(1660px) {
                padding-left: 33px;
            }
            @include media(1440px) {
                font-size: 14px;
            }
            &::placeholder {
                color: #ADBAC3;
            }
        }
        
    }
    &__vision{
        display: flex;
        align-items: center;
        max-width: 164px;
        cursor: pointer;
        margin-right: 10px;
        @include media(1440px) {
            max-width: 136px;
        }
        @include media(900px) {
            max-width: 124px;
        }
        &_logo {
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            background-image: url(../img/svg/vision.svg);
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 15px;
            @include media(1440px) {
                margin-right: 8px;
            }
            @include media(900px) {
                width: 32px;
                height: 32px;
            }
        }
        &_text {
            font-size: 16px;
            line-height: 1.4;
            text-decoration: underline;
            text-underline-offset: 2px;
            @include media(1440px) {
                font-size: 12px;
            }
        }
    }
}