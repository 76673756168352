.banner {
  position: relative;
  min-height: 460px;
  height: 460px;
  padding: 85px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include media(1440px) {
    background-size: cover;
  }

  @include media(1024px) {
    min-height: 385px;
    height: 385px;
    padding: 65px 0;
  }

  @include media(600px) {
    min-height: 270px;
    height: 270px;
    padding: 45px 0;
  }

  @include media(450px) {
    min-height: 200px;
    height: 200px;
    padding: 20px 0;
  }
  &_inner_page {
      .container {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        p {
            font-size: 24px;
        }
      }
  }
  &_layer {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 80%;
      height: 100%;
    }

    &::before {
      left: 0;
      background: linear-gradient(90.05deg, #EBF7F9 49.98%, rgba(235, 247, 249, 0) 101.34%);
  
      @include media(1440px) {
        width: 100%;
      }
  
      @include media(767px) {
        background: linear-gradient(90.05deg,#ebf7f9 59.98%,rgba(235,247,249,0) 141.34%);
      }
 
    }
  }


  &__info {
    max-width: 480px;
    font-weight: 500;
    color: #001A11;

    @include media(600px) {
      max-width: 380px;
    }

    @include media(450px) {
      max-width: 100%;
    }
  }

  &__pretitle {
    display: block;
    font-size: 30px;
    line-height: 44px;
    font-weight: bold;

    @include media(1024px) {
      max-width: 255px;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }

    @include media(600px) {
      font-size: 16px;
    }

    @include media(450px) {
      max-width: 190px;
      font-size: 14px;
    }
  }

  &__title {
    font-size: 40px;
    line-height: 54px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;

    @include media(1024px) {
      font-size: 30px;
      line-height: 40px;
    }

    @include media(600px) {
      font-size: 26px;
      line-height: 36px;
    }

    @include media(450px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__text {
    display: block;
    font-size: 18px;
    line-height: 30px;

    @include media(1024px) {
      margin-top: 20px;
      font-size: 16px;
      line-height: 24px;
    }

    @include media(600px) {
      font-size: 14px;
      line-height: 20px;
    }

    @include media(450px) {
      line-height: 17px;
    }
  }

  &__upper-text {
    margin-bottom: 60px;
    max-width: 1300px;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -0.04em;
    color: #fff;
    text-transform: uppercase;

    @include media(1440px) {
      margin-bottom: 45px;
      max-width: 980px;
      font-size: 30px;
      line-height: 44px;
    }

    @include media(1024px) {
      font-size: 26px;
      line-height: 36px;
      letter-spacing: normal;
    }

    @include media(600px) {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__button {
    margin-top: 30px;
    border-radius: 5px;
    box-shadow: none;

    @include media(1024px) {
      margin-top: 40px;
    }

    @include media(600px) {
      margin-top: 20px;
    }
  }
}
