body {
  letter-spacing: normal;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: $text_main_color;
  background: #fff;
}

.container {
    max-width: $content_limit + 60px;
    padding: 0 30px;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    @include media(1660px) {
      padding: 0 100px;
    }

    // @include media(1440px) {
    //   max-width: 1278px;
    //   padding:  0 25px;
    // }

    @include media(1300px) {
      padding: 0 50px;
    }
    
    @include media(1200px) {
      padding: 0 15px;
    }
    
    // @include media(768px) {
    //   padding: 0 13px;
    // }

    @include media(600px) {
      padding: 0 5px;
    }

    // @include media(450px) {
    //   padding: 0 11px;
    // }
}

.inline-flex {
  display: inline-flex;
}

.flex {
  display: flex;
}

.flex, .inline-flex {
  &.jsb {
    justify-content: space-between;
  }

  &.jfs {
    justify-content: flex-start;
  }

  &.jfe {
    justify-content: flex-end;
  }

  &.jc {
    justify-content: center;
  }

  &.ac {
    align-items: center;
  }

  &.afs {
    align-items: flex-start;
  }

  &.afe {
    align-items: flex-end;
  }

  &.fdc {
    flex-direction: column;
  }
}

.mm-navbar, .mm-panel, .mm-panels {
  background-color: $main_color!important;
}

.bold_text {
    font-weight: bold;
}

.mtb {
    margin-top: 100px!important;
    @include media(1440px) {
        margin-top: 75px!important;
    }

  @include media(1024px) {
    margin-top: 40px!important;
  }
}
h1, h2, h3, h4 {
    letter-spacing: normal;
    @include media(600px) {
        letter-spacing: .09rem;
    }
}
