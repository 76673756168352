.fed_docs {
    &__wrapper {
        flex-wrap: wrap;
    }
    &__unit {
        width: 100%;
        margin-bottom: 12px;
        @include media(600px) {
            align-items: flex-start!important;
        }
    }
    &__icon {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        margin-right: 16px;
        transform: translateY(-2px);
        align-self: top;
        img {
            width: 100%;
        }
        @include media(600px) {
            transform: translateY(10px);
            height: 11px;
        }
    }
    &__link {
        @include media(768px) {
            font-size: 14px;
        }
        &:hover {
            text-decoration: underline;
            text-underline-offset: 2px;
        }
    }
}
.prices {
    .fed_docs {
        &__unit {
            margin-bottom: 50px;
        }
        &__icon {
            width: 50px;
            height: 50px;
            margin-right: 30px;
            @include media(768px) {
                width: 26px;
                height: 26px;
            }
        }
        &__link {
            font-size: 26px;
            @include media(768px) {
                font-size: 18px;
            }
            &:hover {
                text-underline-offset: 4px;
            }
        }
    }
}
.desktop_br {
    @include media(600px) {
        display: none;
    }
}
.page_gallery .subtitle {
    @include media(600px) {
        margin-bottom: 20px;
    }
}