.footer {
  position: relative;
  padding: 50px 0;
  background-color: #ffffff;

  @include media(1024px) {
    padding: 38px 0;
  }

  @include media(1024px) {
    padding: 38px 0;
  }

  @include media(450px) {
    padding: 10px 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/assets/templates/img/dots_footer.png);
    opacity: 0.03;
  }

  &__logo {
    width: 275px;
    height: 66px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../img/svg/logoNew.svg);

    @include media(1024px) {
      width: 177px;
      height: 42px;
    }

    @include media(600px) {
      width: 78px;
      background-position: center left;
      background-size: auto 100%;
    }
  }

  &__social {
    align-self: flex-end;
    fill: rgba(#fff, 0.6);

    @include media(1024px) {
      order: 0;
    }
  }

  &__bm {
    display: inline-block;
    width: 103px;
    height: 60px;
    margin-right: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../img/svg/bm_logo.svg);

    @include media(1024px) {
      margin-right: 10px;
    }

    @include media(600px) {
      margin-right: 5px;
      width: 75px;
      height: 35px;
    }

    @include media(450px) {
      margin-right: 0;
    }
  }

  &__copyright {
    display: inline-block;

    @include media(450px) {
      display: none;
    }
  }

  &__text {
    display: block;
    font-size: 18px;
    font-weight: 200;
    line-height: 24px;
    color: $text_main_color;

    @include media(1024px) {
      font-size: 12px;
      line-height: 20px;
    }

    @include media(600px) {
      font-size: 11px;
    }
  }

  &__link {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
  
  &__description {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
}
