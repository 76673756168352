.slider {
  margin: auto;
  max-width: $content_limit;
  color: #001A11;

  &__unit {
    padding: 70px 50px;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    
    .container {
        height: 100%;    
    }
    
    @include media(1440px) {
      padding: 75px 0;
    }

    @include media(1024px) {
      padding: 45px 11px;
      min-height: 345px;
    }

    @include media(700px) {
        height: 374px;
    }
    @include media(768px) {
      background-position: 10% center;
    }

    @include media(600px) {
      background-position: 15% center;
    }

    @include media(450px) {
      padding: 20px 0;
      min-height: 240px;
      background-position: 25% center;
    }
  }
  
  &__content_wrapper {
    width: 560px;
    height: 100%;
    display: flex;
    flex-direction: column;
    @include media(767px) {
        width: 100%;
    }
  }
  &__title {
    margin-bottom: 35px;
    font-size: 54px;
    font-weight: 600;
    line-height: 1;
    width: 80%;

    @include media(1024px) {
      margin-bottom: 30px;
      font-size: 36px;
      line-height: 42px;
    }

    @include media(450px) {
      margin-bottom: 25px;
      padding: 0 10px;
      font-size: 28px;
      line-height: 34px;
    }
  }

  &__text {
    margin-bottom: 20px;
    font-size: 41px;
    font-weight: 400;
    line-height: 58px;
    
    ul {
        display: flex;
        flex-wrap:wrap;
        li {
            padding-left: 34px;
            font-size: 24px;
            line-height: 1.3;
            position: relative;
            @include media(700px) {
                font-size: 18px;
            }
            &:before {
                content: '';
                display: block;
                width: 14px;
                height: 14px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-image: url(../img/svg/check.svg);
                background-position: center;
                
            }
        }
        &.two_col {
            li {
                width: 50%;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }
        }
        &.custom_columns {
            li {
                &:nth-child(odd) {
                    width: 47%;
                    @include media(700px) {
                        width: 100%;
                    }
                }
                &:nth-child(even) {
                    width: 53%;
                    @include media(700px) {
                        width: 100%;
                    }
                }
            }
        }
    }

    @include media(1024px) {
      margin-bottom: 30px;
      font-size: 26px;
      line-height: 36px;
    }

    @include media(450px) {
      margin-bottom: 20px;
      padding: 0 10px;
      font-size: 20px;
      line-height: 30px;
    }
  }
  
  &__button {
      box-shadow: none;
      border-radius: 5px;
      margin-top: auto;
  }
}
