/* fira-sans-200italic - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/fira-sans-v10-cyrillic_latin-200italic.eot'); /* IE9 Compat Modes */
  src: local('Fira Sans ExtraLight Italic'), local('FiraSans-ExtraLightItalic'),
     url('../fonts/fira-sans-v10-cyrillic_latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../fonts/fira-sans-v10-cyrillic_latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-200italic.woff') format('woff'), /* Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
     url('../fonts/fira-sans-v10-cyrillic_latin-200italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-200 - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/fira-sans-v10-cyrillic_latin-200.eot'); /* IE9 Compat Modes */
  src: local('Fira Sans ExtraLight'), local('FiraSans-ExtraLight'),
     url('../fonts/fira-sans-v10-cyrillic_latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../fonts/fira-sans-v10-cyrillic_latin-200.woff2') format('woff2'), /* Super Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-200.woff') format('woff'), /* Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
     url('../fonts/fira-sans-v10-cyrillic_latin-200.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-300 - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/fira-sans-v10-cyrillic_latin-300.eot'); /* IE9 Compat Modes */
  src: local('Fira Sans Light'), local('FiraSans-Light'),
     url('../fonts/fira-sans-v10-cyrillic_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../fonts/fira-sans-v10-cyrillic_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-300.woff') format('woff'), /* Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
     url('../fonts/fira-sans-v10-cyrillic_latin-300.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-300italic - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/fira-sans-v10-cyrillic_latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Fira Sans Light Italic'), local('FiraSans-LightItalic'),
     url('../fonts/fira-sans-v10-cyrillic_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../fonts/fira-sans-v10-cyrillic_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-300italic.woff') format('woff'), /* Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
     url('../fonts/fira-sans-v10-cyrillic_latin-300italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-regular - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/fira-sans-v10-cyrillic_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Fira Sans Regular'), local('FiraSans-Regular'),
     url('../fonts/fira-sans-v10-cyrillic_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../fonts/fira-sans-v10-cyrillic_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-regular.woff') format('woff'), /* Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
     url('../fonts/fira-sans-v10-cyrillic_latin-regular.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-italic - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/fira-sans-v10-cyrillic_latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Fira Sans Italic'), local('FiraSans-Italic'),
     url('../fonts/fira-sans-v10-cyrillic_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../fonts/fira-sans-v10-cyrillic_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-italic.woff') format('woff'), /* Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
     url('../fonts/fira-sans-v10-cyrillic_latin-italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-500 - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/fira-sans-v10-cyrillic_latin-500.eot'); /* IE9 Compat Modes */
  src: local('Fira Sans Medium'), local('FiraSans-Medium'),
     url('../fonts/fira-sans-v10-cyrillic_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../fonts/fira-sans-v10-cyrillic_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-500.woff') format('woff'), /* Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
     url('../fonts/fira-sans-v10-cyrillic_latin-500.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-500italic - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/fira-sans-v10-cyrillic_latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Fira Sans Medium Italic'), local('FiraSans-MediumItalic'),
     url('../fonts/fira-sans-v10-cyrillic_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../fonts/fira-sans-v10-cyrillic_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-500italic.woff') format('woff'), /* Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
     url('../fonts/fira-sans-v10-cyrillic_latin-500italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-700 - cyrillic_latin */
@font-face {
  font-family: 'Fira Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/fira-sans-v10-cyrillic_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Fira Sans Bold'), local('FiraSans-Bold'),
     url('../fonts/fira-sans-v10-cyrillic_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../fonts/fira-sans-v10-cyrillic_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-700.woff') format('woff'), /* Modern Browsers */
     url('../fonts/fira-sans-v10-cyrillic_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
     url('../fonts/fira-sans-v10-cyrillic_latin-700.svg#FiraSans') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Proxima Nova';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/ProximaNova-Regular.eot');
    src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('../fonts/ProximaNova-Regular.woff') format('woff'),
        url('../fonts/ProximaNova-Regular.ttf') format('truetype');
}

